const APIs = {
    // localDebug: 'http://192.168.1.38:8001',
   localDebug:'https://api.mymovingway.com',

prodRelease: {
  api: process.env.VUE_APP_API,
}
};

export default APIs.prodRelease.api

// export default APIs.localDebug
