export default ((
    TrainerId,
    UserId,
    PersonId,
    CompanyBranchId,
    FirstName,
    LastName,
    Email, 
    PhoneNumber, 
    Birthday, 
    Gender, 
    UserImg 
    ) => ({
    TrainerId:TrainerId,
    UserId:UserId,
    PersonId:PersonId,
    CompanyBranchId:CompanyBranchId,
    FirstName:FirstName,
    LastName:LastName,
    Email:Email,
    PhoneNumber: PhoneNumber,
    Birthday:Birthday,
    Gender:Gender,
    UserImg: UserImg
}));