export default (CompanyBranchId, FirstName, LastName, Email, PhoneNumber, Birthday,Identity, Gender , CityId, TownId,IsAddressesEqual, Address, UserImg) => ({
   CompanyBranchId,
   FirstName,
   LastName,
   Email,
   PhoneNumber,
   Birthday,
   Gender,
   UserImg,
   IdentificationNumber:Identity,
   //CountryId,
   CityId,
   TownId,
   IsAddressesEqual,
   Address
})
