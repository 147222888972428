const language=sessionStorage.getItem('root/language')
export default {
    namespaced: true,
    state: {
        language:language?language:'tr',
        storeLoaded:false
    },
    getters: {
        language: (state) => state.language,
       storeLoaded:(state)=>state.storeLoaded
    },
    mutations: {
      storeLoaded: (state, payload) => state.storeLoaded = payload,

        language: (state,payload) => state.language = payload,
        initialiseStore (state) {
            const stateVariables = Object.keys(state)
            stateVariables.forEach(stateKey=>{
              try{
                const moduleStateKeys = Object.keys(state[stateKey]);
                moduleStateKeys.forEach(childStateKey=>{
                  const temp = sessionStorage.getItem(stateKey + "/" + childStateKey);
                  if(temp){
                    try {
                      (state[stateKey])[childStateKey] =JSON.parse(temp);
                    }catch{
                      (state[stateKey])[childStateKey] =temp;
                    }
                  }
                })
              }catch(err){
                if (sessionStorage.getItem(stateKey)) {
                  try {
                    state[stateKey]  =  JSON.parse(sessionStorage.getItem(stateKey))
                  } catch (err) {
                    state[stateKey]  =  sessionStorage.getItem(stateKey)
                  }
                }
              }
            })
          },
    },

    actions: {



    }
}
