export default ((
    ActivityDetailCardView,
    CustomerProductCardView,
    TrainerDetailCardView,
    TrainerProductCardView) => ({
    ActivityDetailCardView:{
        activity_color:ActivityDetailCardView.activity_color,
        activity_id:ActivityDetailCardView.activity_id,
        activity_name:ActivityDetailCardView.activity_name
    },
    CustomerProductCardView:{
        amount_activities_consumed:CustomerProductCardView.amount_activities_consumed,
        customer_product_sales_id:CustomerProductCardView.customer_product_sales_id,
        person_id:CustomerProductCardView.person_id,
        person_price_id:CustomerProductCardView.person_price_id,
        quantity:CustomerProductCardView.quantity,
        amount_activities_consumed:CustomerProductCardView.amount_activities_consumed,
    },
    TrainerDetailCardView:{
        trainer_id: TrainerDetailCardView.trainer_id,
        trainer_first_name: TrainerDetailCardView.trainer_first_name,  
        trainer_last_name: TrainerDetailCardView.trainer_last_name,
        trainer_image:TrainerDetailCardView.trainer_image,
    },
    TrainerProductCardView:{
        trainer_product_id:TrainerProductCardView.trainer_product_id,
        product_price:TrainerProductCardView.product_price,
        product_session:TrainerProductCardView.product_session,
        trainer_product_description:TrainerProductCardView.trainer_product_description,
        trainer_product_image:TrainerProductCardView.trainer_product_image,
        trainer_product_title:TrainerProductCardView.trainer_product_title,
    },
}));