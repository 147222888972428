import axios from 'axios'
import store from '../../store/index'

export default {
  executeQueryWithAddress(address, action, version, data, loading = true) {
    store.commit('app/loading', loading)
    const paramettesrs = {
      action,
      version,
      parameters: {
        ...data,
      },
    }
    return axios({
      method: 'post',
      url: `${address}?request=${JSON.stringify(paramettesrs)}`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${store.getters['auth/token']}`,
      },
    })
  },
  executePostFormData(action, version, data = null, loading = true) {
    store.commit('app/loading', loading)
    const formData = new FormData()
    formData.append('Action', action)
    formData.append('Version', version)
    if (data) formData.append('Parameters', JSON.stringify(data))
    const configuration = {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${store.getters['auth/token']}`,
        // authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjA2QTYxRjFENDVEMkJFRDYzMjQ4NDlFMEQ2MjU5Q0I0IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2MzM5NjM1ODAsImV4cCI6MTYzMzk2NzE4MCwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo0NDMxMCIsImF1ZCI6WyJneHB0X2FwaV9yZXNvdXJjZSIsImlkZW50aXR5X2VtYWlsX2FwaV9yZXNvdXJjZSIsImlkZW50aXR5X2lhbV9hcGlfcmVzb3VyY2UiXSwiY2xpZW50X2lkIjoiZ3hwdF9hcGlfY2xpZW50Iiwic3ViIjoiNGFkZWE2YjAtYjRjYS00Y2NhLWI1M2UtYWVlZDhiNmI5ZWY0IiwiYXV0aF90aW1lIjoxNjMzOTYzNTgwLCJpZHAiOiJsb2NhbCIsIm5hbWUiOiJtdWhhbW1ldCIsInJvbGUiOiJJZGVudGl0eS5Vc2VyIiwianRpIjoiOTlGOUZGQTA1RTZEQ0NDRDlFRDU5RTI3RjIwRkQxNkIiLCJpYXQiOjE2MzM5NjM1ODAsInNjb3BlIjpbImVtYWlsIiwiZ3hwdF9hcGlfc2NvcGUiLCJpZGVudGl0eV9lbWFpbF9hcGlfc2NvcGUiLCJpZGVudGl0eV9pYW1fYXBpX3Njb3BlIiwib3BlbmlkIiwicHJvZmlsZSIsInJvbGVzIl0sImFtciI6WyJwd2QiXX0.kRSkjixr5Eus9t2xwveoxWI16d8-yq8Mf_ygp17-G7P11UeG9S6TfgDmgElKBPbJ9o2jMX25gzPn8hlUBIc1K-HVfuLT4Kh5JbNn7_zrDOO2rvrJR4VPLemZLKKvZm2CeBEsVjAgLTKDBZW2QP2u-3WR4m43BLidsg-UFp7plyPp4MLNalBIKgDDNYgCGO0MJvWzoerf4XYAi2hT_jsIcRPD8G0XshTEawfuMpxIZmci34V0fqXijdhZPi8YYbYWx1uP_YRYTJ39SRV17crwaQPrggqNUcHuenFTDmICJN-bTGXYoWFE94qfBfZu-mgugZvT1pMlY8vA6-TDBwC8Tw'
      },
      timeout: 10000,
    }
    // return axios.post('http://192.168.1.103:8001/api/v1/FinTech/Execute', formData, configuration)
    return axios.post('/api/v1/FinTech/Execute', formData, configuration)
  },
  executePostFormDataWithNode(action, version, data, nodeID = null, loading = true) {
    store.commit('app/loading', loading)
    // Bu proje için farklı node'lar bulunduğundan nodeID üzerinden API adresindeki execute'a istek atıyor.
    if (!nodeID) return Promise.reject(new Error('NodeID mevcut değil!'))
    const formData = new FormData()
    formData.append('Action', action)
    formData.append('Version', version)
    formData.append('NodeID', nodeID)
    if (data) formData.append('Parameters', JSON.stringify(data))

    const configuration = {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${store.getters['auth/token']}`,
      },
      timeout: 580000,
    }
    return axios.post('api/v1/Node/Execute', formData, configuration)
  },
}
