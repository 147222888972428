import FintechPost from './BaseConnectCommand/FintechPost'
import Get from './BaseConnectCommand/Get'
import Post from './BaseConnectCommand/Post'

export default {



     // /api/Appointment/GetAppointment
     //cf5e828c-e671-49b1-a747-13743f6ff13d türkiyenin ülke ID si
     list_city_of_the_country(data={CountryId :"cf5e828c-e671-49b1-a747-13743f6ff13d"})
     {
        
       //data={CountryId :"cf5e828c-e671-49b1-a747-13743f6ff13d"}
        return Post.postFormData('/api/Location/ListCityOfTheCountry', data);
    },

    list_towns_of_the_city(CityId)
    {
      const data = {CityId} 
      //data={CountryId :"cf5e828c-e671-49b1-a747-13743f6ff13d"}
       return Post.postFormData('/api/Location/ListTownsOfTheCity', data);
   }


 // /api/Appointment/GetAppointment
//  self_company_create(data={Name,Title, CompanyOfficialTitle,PhoneNumber, CityId, TownId, TaxAdministration, TaxCode, Adress,Email,Iban,CompanyType}){
//     return Post.postFormData('/api/Company/SelfCompanyCreate', data);
// },

}