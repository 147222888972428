import models from "../../models/index";
export default {
  namespaced: true,
  state: {
    events: [
      {
        title: "My Event",
        start: "2021-10-20 08:00:00",
        end: "2021-10-20 09:00:00",
        textColor: "#fff",
        description: "Sabah sporu",
        //eventTextColor:'white',
        type: 2, // Custom field
      },
      {
        start: "2021-10-21T12:00:00",
        end: "2021-10-21T13:00:00",
        overlap: false, //taşınamama, sürüklenememe
        rendering: "background",
        color: "#ff9f89",
        editable: false,
        deletable: false,
      },
    ],
  },
  getters: {
    events: (state) => state.events,
  },
  mutations: {
    events: (state, payload) => (state.events = payload),
  },
  actions: {
    addAppointment(store, appointment = { ...models.appointment }) {
      const event = {
        title: appointment.title,
        editable: false,
        start: appointment.startDate + " " + appointment.startTime,
        end: appointment.endDate + " " + appointment.endTime,
      };

      store.state.events.push(event);
    },
    deleteAppointment(store, clickEvent) {
      clickEvent.remove();
    },
  },
};
