export default ((
    AppointmentCompanyBranch,
    AppointmentTimeTable,
    AppointmentRoom,
    AppointmentTrainer,
    AppointmenActivity,
    AppointmentStudioDoor,
    IsCanceledButton,
    IsDoorButton,
    IsActiveCard,
    ) => ({
        AppointmentCompanyBranch:{
            company_id:AppointmentCompanyBranch.CompanyId,
            company_branch_id:AppointmentCompanyBranch.CompanyBranchId,
            company_branch_name:AppointmentCompanyBranch.CompanyBranchName,
           // company_branch_title:AppointmentCompanyBranch.CompanyBranchTitle
        },
        AppointmentTimeTable:AppointmentTimeTable != null ?{
            time_table_id:AppointmentTimeTable.TimeTableId,
            company_branch_id:AppointmentTimeTable.CompanyBranchId,
            subject_title:AppointmentTimeTable.SubjectTitle,
            description:AppointmentTimeTable.Description,
            trainer_product_id:AppointmentTimeTable.TrainerProductId,
            trainer_id:AppointmentTimeTable.TrainerId,
            activity_id:AppointmentTimeTable.ActivityId,
            activity_id: AppointmentTimeTable.ActivityId,
            time_table_detail_id: AppointmentTimeTable.TimeTableDetailId,
            person_id: AppointmentTimeTable.PersonId,
            start_date: AppointmentTimeTable.StartDate,
            end_date: AppointmentTimeTable.EndDate,
            is_active:AppointmentTimeTable.IsActive,
        }:null,
        AppointmentRoom:AppointmentRoom != null ?{
            room_id:AppointmentRoom.RoomId,
            room_name:AppointmentRoom.RoomName,
            capacity:AppointmentRoom.Capacity
        }:null,
        AppointmentTrainer:AppointmentTrainer != null ?{
            trainer_id:AppointmentTrainer.TrainerId,
            person_id:AppointmentTrainer.PersonId,
            company_branchI_id:AppointmentTrainer.CompanyBranchId,
            first_name:AppointmentTrainer.FirstName,
            last_name:AppointmentTrainer.LastName
        }:null,
        AppointmenActivity:AppointmenActivity != null ?{
            activity_id:AppointmenActivity.ActivityId,
            activity_name:AppointmenActivity.ActivityName,
            activity_color:AppointmenActivity.ActivityColor,
            cctivity_time:AppointmenActivity.ActivityTime
        }:null,
        AppointmentStudioDoor:AppointmentStudioDoor != null ?{
            room_id:AppointmentStudioDoor.RoomId,
            door_name:AppointmentStudioDoor.DoorName,
            duration_before_start_date:AppointmentStudioDoor.DurationBeforeStartDate,
            duration_after_start_date:AppointmentStudioDoor.DurationAfterStartDate,
            duration_door_open:AppointmentStudioDoor.DurationDoorOpen,
            is_door:AppointmentStudioDoor.IsDoor
        }:null,
        is_canceled_button:IsCanceledButton,
        is_door_button:IsDoorButton,
        is_active_card : IsActiveCard

}));