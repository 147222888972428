import Vue from 'vue'
Vue.mixin({
    data() {
      return {
        resolution: null,
      }
    },
    computed: {                                                                                                                                                                                                                                                                                                                                                                                                       
        isMobile(){
            const resolution = this.$store.state.app.windowWidth;
            if(resolution >= this.$consts.mobileResulution){
                return false;
            }else if(resolution < this.$consts.mobileResulution){
                return true;
            }
        },
        isTablet(){
            const resolution = this.$store.state.app.windowWidth;
            if(resolution >= this.$consts.tabletResolution){
                return false;
            } else if(resolution < this.$consts.tabletResolution){
                return true;
            }
        }
    }
})