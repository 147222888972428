import FintechPost from './BaseConnectCommand/FintechPost'
export default {

    get_studio_room_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_room_list',1,data)
    },
    room_create(p_company_branch_id,p_name,p_is_active){
        const data = {p_company_branch_id, p_name,p_is_active}
        return FintechPost.executePostFormData('room_create',1,data)
    },
    update_room(p_room_id,p_company_branch_id,p_name,p_is_active){
        const data = {p_room_id,p_company_branch_id, p_name,p_is_active}
        return FintechPost.executePostFormData('update_room',1,data)
    },

    update_capacity(p_room_id, p_company_branch_id, p_capacity){
        const data= {p_room_id, p_company_branch_id, p_capacity}
        return FintechPost.executePostFormData('update_capacity', 1, data)
    },

    /**Activitelere göre salonların kapasitesini tanımla yada güncelle */
    update_or_create_room_capacity(p_company_branch_id,p_activity_types){
        const data = {p_company_branch_id,p_activity_types}
        return FintechPost.executePostFormData('update_or_create_room_capacity',1,data)
    },

    //sadece aktif olan roomları getir
    get_room_company_branch(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_room_company_branch', 1, data)
    }

}