/**
 * TC kimlik numarası inputu için yapıldı. eper say haricinde bir değer girilirse son karakteri silip return ediyor
 * örnek: input "123a" > return "123"
 * @param value
 * @returns {string|*}
 */
export default function(value){
  var reg = new RegExp('^[0-9]+$');
  if(reg.test(value))return value
  else return value.substring(0,value.length-1)

}
