import personCreateModel from './Person/personCreateModel'
import personEditModel from '@/models/Person/personEditModel'
import facilityPersonCreateModel from './Person/facilityPersonCreateModel'
import facilityPersonEditModel from '@/models/Person/facilityPersonEditModel'
import customerAppointmentDto from '@/models/FacilityModels/customerAppointmentDto'
import customerProductCardView from '@/models/CustomerProductModels/customerProductCardViewModel'
import createAppointmenProductCardView from '@/models/CustomerProductModels/createAppointmenProductCardView'
import trainerWithActivity from '@/models/TrainerModels/trainerWithActivity'
import CustomerAppointmentModel from '@/models/CustomerAppointmentModels/CustomerAppointmentCard'
export default {
  personCreateModel,
  personEditModel,
  facilityPersonCreateModel,
  facilityPersonEditModel,
  customerAppointmentDto,
  customerProductCardView,
  createAppointmenProductCardView,
  trainerWithActivity,
  CustomerAppointmentModel,
}