import axios from 'axios'
import store from '../../store/index'

export default {
  postFormData(api, data = {}) {
    //console.log('post form data çalıştı')
    const configuration = {
      headers: {
        'content-type': 'application/json',
      },
      timeout: 580000,
    }
    if (store.getters['auth/token'] !== undefined) 
    //configuration.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    configuration.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    const formData = new FormData()
    const keys = Object.keys(data)
    const values = Object.values(data)

    for (let i = 0; i < keys.length; i++) {
      formData.append(keys[i]+"", values[i])
    }
    store.commit('app/loading', true)
    return axios.post(api, formData, configuration)
  },
  postFileDataArray(api, arr, key) { // Çoklu dosya gönderimi, key: api tarafındaki parametrenin adı
    const configuration = {
      headers: {
        'content-type': 'multipart/formdata',
      },
      timeout: 580000,
    }
    if (store.getters['auth/token'] !== undefined) configuration.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    const formData = new FormData()

    for (let i = 0; i < arr.length; i++) {
      formData.append(key, arr[i])
    }
    store.commit('app/loading', true)
    return axios.post(api, formData, configuration)
  },
  postJsonData(api, data) {
    const configuration = {
      headers: {
        'content-type': 'application/json',
      },
      timeout: 580000,
    }
    if (store.getters['auth/token'] !== undefined) configuration.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    store.commit('app/loading', true)
    return axios.post(api, data, configuration)
  },
  postRoot(api) {
    const configuration = {
      headers: {
        'content-type': 'application/json',
      },
      timeout: 580000,
    }
    if (store.getters['auth/token'] !== undefined) {
      configuration.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    }
    // Store.commit('setLoading',true);
    return axios.post(api, {}, configuration)
  },
  Body(api, body) {
    const configuration = {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      timeout: 580000,
    }
    if (store.getters['auth/token'] !== undefined) {
      configuration.headers.Authorization = `Bearer ${store.getters['auth/token']}`
    }
    store.commit('app/loading', true)
    return axios.post(api, body, configuration)
  },
}
