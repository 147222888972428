/**
 * Girilen değer eğer null veya undefined ise defaultValue değişkenindeki değeri geri döndürür
 * @param value
 * @param defaultValue
 * @returns {*}
 * @constructor
 */
export default function (value,defaultValue){
    if(value === null || value === undefined)
        return defaultValue
    else
        return value
}
