import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {

    record_time_table(p_company_branch_id, p_subject_title, p_description="", p_trainer_id, p_activity_id, p_start_date, p_end_date, p_room_id, p_capacity, p_participants_list){
        const data = {p_company_branch_id, p_subject_title, p_description, p_trainer_id,p_activity_id, p_start_date, p_end_date, p_room_id, p_capacity, p_participants_list}
        return FintechPost.executePostFormData('record_time_table', 1, data)
    },
    attendance_by_time_period(CompanyBranchId,StartDate,EndDate,SelectedPeriod)
    {
        const data = {CompanyBranchId,StartDate,EndDate,SelectedPeriod}
        return Post.postFormData('api/Report/AttendanceByTimePeriod', data)
    },


    packages_sold(CompanyBranchId,StartDate,EndDate,SelectedPeriod)
    {
        const data = {CompanyBranchId,StartDate,EndDate,SelectedPeriod}
        return Post.postFormData('api/Report/PackagesSold', data)
    },

    trainer_activity_count(CompanyBranchId,StartDate,EndDate,Search)
    {
        const data = {CompanyBranchId,StartDate,EndDate,Search}
        return Post.postFormData('api/Report/TrainerActivityCount', data)
    },

    customer_activity_count(CompanyBranchId,StartDate,EndDate,Search)
    {
        const data = {CompanyBranchId,StartDate,EndDate,Search}
        return Post.postFormData('api/Report/CustomerActivityCount', data)
    },

}
