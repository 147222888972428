// import './const/serviceConfig'
// // eslint-disable-next-line camelcase
// import BOT_HataliTesvik from '@/services/BOT_HataliTesvik'
// import Yersiz from '@/services/Yersiz'
// import Account from './Account'
// import AccountAdds from './AccountAdds'
// import Address from './Address'
// import Analytics from './Analytics'
// import Announce from './Announce'
// import Branch from './Branch'
// import BOT from './BOT'
// // eslint-disable-next-line camelcase
// import BOT_CustomerNodes from './BOT_CustomerNodes'
// // eslint-disable-next-line camelcase
// import BOT_Task from './BOT_Task'
// // eslint-disable-next-line camelcase
// import BOT_TaskRequestState from './BOT_TaskRequestState'
// import Calc from './Calc'
// import Company from './Company'
// import Customer from './Customer'
// import CustomerContract from './CustomerContract'
// import CustomerIncitement from './CustomerIncitement'
// import EBildirge from './EBildirge'
// import LOG from './LOG'
// import MenuRoleGroup from './MenuRoleGroup'
// import SGKParameters from './SGKParameters'
// import Storage from './Storage'
// import SupportStaff from './SupportStaff'
// import User from './User'
// import SizeOzel from './SizeOzel'


import DistributorService from './DistributorService.js'
import StudioService from './StudioService.js'
import SettingService from './SettingService.js'
import RoomService from './RoomService.js'
import ActivityService from './ActivityService.js'
import TrainerService from './TrainerService.js'
import AccountService from './AccountService.js'
import ShoppingService from './ShoppingService.js'
import CustomerService from './CustomerService.js'
import FacilityService from './FacilityService.js'
import CustomerProductSaleService from './CustomerProductSaleService.js'
import PurchasedService from './PurchasedService.js'
import ProductService from './ProductService.js'
import AppointmentService from './AppointmentService.js'
import SchedulerService from './SchedulerService.js'
import ConditionService from './ConditionService.js'
import LocationService from'./LocationService.js'
import ReportService from'./ReportService.js'


// import studio from './studio.js'

export default {
  DistributorService,
  StudioService,
  SettingService,
  RoomService,
  ActivityService,
  TrainerService,
  AccountService,
  ShoppingService,
  CustomerService,
  FacilityService,
  CustomerProductSaleService,
  PurchasedService,
  ProductService,
  AppointmentService,
  SchedulerService,
  ConditionService,
  LocationService,
  ReportService
}
