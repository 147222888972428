import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'

export default {
    // api/CustomerProductSale/CreateProductSale
    CreateProductSale(data= {ProductPriceId: '', PersonId: '', Quantity: '',PaymentTypeId:''}){
        return Post.postFormData('/api/CustomerProductSale/CreateProductSale', data)
    },


    FacilityCreateProductSale(data= {ProductPriceId: '', PersonId: '', Quantity: '',PaymentTypeId:''}){
        return Post.postFormData('/api/CustomerProductSale/FacilityCreateProductSale', data)
    },

     // api/CustomerProductSale/CancelProductSale
     // Paratika da da işlem gerçekleştirir.
     CancelProductSale(customer_product_sales_id){
      const  data= {prmCustomerProductSaleId: customer_product_sales_id}
        return Post.postFormData('/api/CustomerProductSale/CancelProductSale', data)
    },
         // api/CustomerProductSale/CancelProductSale
    ConsumedAcivitySales(p_company_branch_id){
            const  data= {compnayBranchId: p_company_branch_id}
              return Post.postFormData('/api/CustomerProductSale/ConsumedAcivitySales', data)
    },

    //Odeme onayını aktif et sadece database de Paratikada bir işlem yapmaz
    PaymentConfirmCompleted(customer_product_sales_id){
        const  data= {prmCustomerProductSaleId: customer_product_sales_id, paymentConfirmStatus:true}
          return Post.postFormData('/api/CustomerProductSale/PaymentConfirmChange', data)
      },

    //Odeme onayını iptal et sedece database de Paratika da işlem yapmaz
    PaymentConfirmCanceled(customer_product_sales_id){
        const  data= {prmCustomerProductSaleId: customer_product_sales_id, paymentConfirmStatus:false}
          return Post.postFormData('/api/CustomerProductSale/PaymentConfirmChange', data)
    },


    GetCustomerProductDetails(p_person_id,p_company_branch_id){
      const data = {personId:p_person_id,companyBranchId:p_company_branch_id}
      return Post.postFormData('/api/CustomerProductSale/GetCustomerProductDetails', data)
  },

  GetCustomerProductDetail(p_company_branch_id,p_customer_product_sales_id){
    const data = {companyBranchId:p_company_branch_id,customerProductSalesId:p_customer_product_sales_id}
    return Post.postFormData('/api/CustomerProductSale/GetCustomerProductDetail', data)
},
} 