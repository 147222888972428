export default function (param1, param2, type) {
  switch (type) {
    case '1':
      return param1 + "T" + param2 + ":00+03:00"
    case '2':
      return param1 + "T" + param2 + "+03:00"
    default:
      return param1 + "T" + param2
  }
}
