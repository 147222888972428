import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
import axios from 'axios'
import store from '../store/index'

export default {
    get_customer_products_for_select(p_person_id,p_trainer_id,p_company_branch_id){
        const data = {p_person_id,p_trainer_id,p_company_branch_id}
        return FintechPost.executePostFormData('get_customer_products_for_select', 1, data)
    },

    /** Müsteriye ait tüm ürün listesini PersonId ve CompnayBarnhId ye göre getiris
     * 12.05-2022 
     * Şenel ve Melih Birlikte oluşturdu
     */
    get_customer_all_products(p_person_id,p_company_branch_id){
        const data = {p_person_id,p_company_branch_id}       
        return FintechPost.executePostFormData('get_customer_all_products', 1, data)
    },
    get_customer_products_for_select_by_activity_id(p_person_id,p_trainer_id,p_company_branch_id,p_activity_id){
        const data = {p_person_id,p_trainer_id,p_company_branch_id,p_activity_id}
        return FintechPost.executePostFormData('customer_products_for_select_by_activity_id', 1, data)
    },
    get_customer_products_for_all_select(p_person_id,p_company_branch_id){
        const data = {p_person_id,p_company_branch_id}
        return FintechPost.executePostFormData('get_customer_products_for_all_select', 1, data)
    },
    get_activity_list_time_table(p_trainer_id, p_activity_id,p_company_branch_id,p_now_date){
        const data = {p_trainer_id, p_activity_id,p_company_branch_id,p_now_date}
        return FintechPost.executePostFormData('get_activity_list_time_table', 1, data)
    },
    // /api/Appointment/GetAppointment
    get_appointment_list(data={CompanyBranchId,TrainerProductId,ActivityId,TrainerId,PersonId,StartDate,EndDate,SelectDate}){
        return Post.postFormData('/api/Appointment/GetAppointment', data);
    },
    // /api/Appointment/setAvaibleAppointment
    set_avaible_appointment(data={CompanyBranchId,TrainerId,DateRange}){
        return Post.postFormData('/api/Appointment/SetAvaibleAppointment', data)
    },
    // /api/Appointment/GetDateRangeAvailableAppointment
    get_date_range_avaible_appointment(data={CompanyBranchId,TrainerId,StartDate,EndDate}){
        return Post.postFormData('/api/Appointment/GetDateRangeAvailableAppointment', data)
    },
    appointment_get_trainer_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('appointment_get_trainer_list', 1, data)
    },
    get_room_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_room_list', 1, data)
    },
    get_branch_name(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_branch_name', 1, data)
    },
    get_new_activity_list_by_trainer_id(p_trainer_id,p_company_branch_id){
        const data = {p_trainer_id, p_company_branch_id}
        return FintechPost.executePostFormData('get_new_activity_list_by_trainer_id', 1, data)
    },
    get_new_room_list_by_date(p_company_branch_id, p_start_date, p_end_date){
        const data = {p_company_branch_id, p_start_date, p_end_date}
        return FintechPost.executePostFormData('get_new_room_list_by_date', 1, data)
    },
    save_appointment_group(p_time_table_id, p_person_id, p_trainer_product_id, p_customer_product_sales_id){
        const data = {p_time_table_id, p_person_id, p_trainer_product_id, p_customer_product_sales_id}
        return FintechPost.executePostFormData('save_appointment_group', 1, data)
    },
    save_appointment_pt(p_company_branch_id, p_subject_title, p_trainer_product_id, p_activity_id, p_trainer_id, p_start_date, p_end_date, p_person_id, p_customer_product_sales_id){
        const data = {p_company_branch_id, p_subject_title,p_trainer_product_id, p_activity_id, p_trainer_id, p_start_date, p_end_date, p_person_id, p_customer_product_sales_id}
        return FintechPost.executePostFormData('save_appointment_pt', 1, data)
    },
    //Sp iptal edildi
    //change_appointment_activity(p_time_table_detail_id) {
    //    const data = {p_time_table_detail_id}
    //    return FintechPost.executePostFormData('change_appointment_activity', 1, data)
    //},
    change_appointment_activity(p_time_table_detail_id) {
        const data = { TimeTableDetailId: p_time_table_detail_id }

        return Post.postFormData('/api/Appointment/ChangeAppointmentActivity', data)
    
    },
    get_my_appointments(p_company_branch_id, p_person_id){
        const data = {p_company_branch_id, p_person_id}
        return FintechPost.executePostFormData('get_my_appointments', 1, data)
    },
    get_customer_appointments(p_company_branch_id, p_person_id, p_appointment_type){
        const data = {CompanyBranchId:p_company_branch_id,PersonId: p_person_id,AppointmentType:p_appointment_type}
        return Post.postFormData('/api/Appointment/CustomerAppointmentCards', data)
    },
    get_activity_id_get_activity_type(p_activity_id,p_company_branch_id){
        const data = {p_activity_id, p_company_branch_id}
        return FintechPost.executePostFormData('get_activity_id_get_activity_type', 1, data)
    },
    get_appointment_details_by_time_table_id(p_time_table_detail_id){
        const data = {p_time_table_detail_id}
        return FintechPost.executePostFormData('get_appointment_details_by_time_table_id', 1, data)
    },
    get_customer_by_activity_id_product(p_activity_id,p_trainer_id){
        const data = {p_activity_id, p_trainer_id }
        return FintechPost.executePostFormData('get_customer_by_activity_id_product', 1, data)
    },
    //time table id'sine göre o randevuya kayıtlı kullanıcıları getireceğiz
    get_participants(p_time_table_id){
        const data = {p_time_table_id}
        return FintechPost.executePostFormData('get_participants', 1, data);
    },
    get_participants_customer_list(p_time_table_id, p_company_branch_id){
        const data = {p_time_table_id, p_company_branch_id}
        return FintechPost.executePostFormData('get_participants_customer_list', 1, data)
    },
    save_participtant_list(p_time_table_id, p_person_id,p_customer_product_sales_id,p_trainer_product_id){
        const data = {p_time_table_id, p_person_id, p_customer_product_sales_id, p_trainer_product_id}
        return FintechPost.executePostFormData('save_participtant_list', 1, data)
    },
    get_timetable_activity(p_person_id){
        const data = {p_person_id}
        return FintechPost.executePostFormData('get_timetable_activity', 1, data)
    },
    save_appointment_manual(p_time_table_id, p_person_id, p_trainer_product_id, p_customer_product_sales_id){
        const data = {p_time_table_id, p_person_id, p_trainer_product_id, p_customer_product_sales_id}
        return FintechPost.executePostFormData('save_appointment_manual', 1, data)
    },
    save_appointment_automatic(p_time_table_id, p_company_branch_id, p_subject_title, p_trainer_product_id, p_activity_id, p_trainer_id, p_start_date, p_end_date, p_person_id, p_customer_sales_id, p_participants_count){
        const data = {p_time_table_id, p_company_branch_id, p_subject_title, p_trainer_product_id, p_activity_id, p_trainer_id, p_start_date, p_end_date, p_person_id, p_customer_sales_id, p_participants_count}
        return FintechPost.executePostFormData('save_appointment_automatic', 1, data)
    },
    save_customer_appointment(p_company_branch_id, p_customer_sales_id, p_person_id, p_start_date, p_end_date,p_time_table_id){
        const data = {CompanyBranchId:p_company_branch_id,
            CustomerSalesId:p_customer_sales_id,
            CustomerPersonId: p_person_id , 
            StartDate:p_start_date, 
            EndDate:p_end_date,
            TimeTableId: p_time_table_id}
        return Post.postFormData('/api/Appointment/CreateCustomerAppointment',data)
    },
    participant_list(p_company_branch_id, time_tabel_id){
        const data = {CompanyBranchId: p_company_branch_id, TimeTabelId: time_tabel_id}
        return Post.postFormData('api/Appointment/ParticipantList',data)
    },
    // Üzerine tıklanan rendevu açılır iken doldurulacak bilgiler
    appointment_shedule_edit(p_company_branch_id, time_tabel_id,trainer_id){
        const data = {CompanyBranchId: p_company_branch_id, TimeTableId: time_tabel_id,TrainerId:trainer_id}
        return Post.postFormData('api/Appointment/AppointmentSheduleEdit',data)
    },
    //Randevu güncelleme işlemi
    appointment_shedule_edit_update(appointmentEditRequest){  
    var appointmentEditRequest = JSON.stringify(appointmentEditRequest);
        var data = new FormData();
        data.append("appointmentValue", appointmentEditRequest);
        return Post.postJsonData('api/Appointment/AppointmentSheduleEditUpdate',data)
    },
    // Üzerine tıklanan rendevu açılır iken doldurulacak bilgiler
    in_activity_participants(p_company_branch_id,activity_id,trainer_id){
            const data = {CompanyBranchId: p_company_branch_id, ActivityId: activity_id,TrainerId:trainer_id}
            console.log({"in_activity_participants" :data})
            return Post.postFormData('api/Appointment/InActivityParticipants',data)
    },
     // Üzerine tıklanan takvim alanından sonra yeni randevu oluşturmak için 
     // gerekli bilgiler
    appointment_shedule_new_request(p_company_branch_id,trainer_id){
        const data = {CompanyBranchId: p_company_branch_id, TrainerId:trainer_id}
        return Post.postFormData('api/Appointment/AppointmentSheduleNew',data)
    },
    //Randevu kapatılır iken yeni kaydelilecek randevu için gerekli bilgiler
    
    appointment_shedule_new_create(appointmentEditRequest){  
    var appointmentEditRequest = JSON.stringify(appointmentEditRequest);
        var data = new FormData();
        data.append("appointmentValue", appointmentEditRequest);
    return Post.postJsonData('api/Appointment/AppointmentSheduleNewCreate',data)
    },
    create_multiple_appointments(appointmentRequest){  
        var appointmentEditRequest = JSON.stringify(appointmentRequest);
        var data = new FormData();
        data.append("appointmentValue", appointmentEditRequest);
    return Post.postJsonData('api/Appointment/MultipleAppointmentsCreate',data)
    },
    delete_multiple_appointments(p_time_table_id,p_is_group )
    {  
        const data = {TimeTableId:p_time_table_id,IsGroup:p_is_group }  
        
        return Post.postFormData('api/Appointment/MultipleAppointmentDelete',data)
    },
    get_available_appointment_days(p_company_branch_id, p_activity_id,p_trainer_id,p_available_date )
    {  
        const data = {CompanyBranchId:p_company_branch_id,  ActivityId:p_activity_id,TrainerId: p_trainer_id,AvailableDate:p_available_date}  
       // console.log(data);
        return Post.postFormData('api/Appointment/GetAvailableAppointmentDays',data)
    },

}