import Vue from "vue";
import { ToastPlugin, ModalPlugin, IconsPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

// import LoadScript from 'vue-plugin-load-script';
// Vue.use(LoadScript);
// Vue.loadScript("https://www.google.com/recaptcha/api.js")
// .then((res) => {
//   // console.log(res);
//   // console.log('okey');
// })
// .catch(() => {
//   // Failed to fetch script
// });
import i18n from "@/libs/i18n";
import router from "./router";
// import store from './store'
import store from "./store";
import App from "./App.vue";
import "./services/const/serviceConfig";
// Global Components
import "./global-components";
//functions
import "./mixins/functions";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter

//Validation
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// function
import functions from "./functions/index";
Vue.prototype.$functions = functions;

//moment library
import moment from 'moment';
Vue.prototype.moment = moment;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// //Excel Export için 
// import JsonExcel from 'vue-json-excel'
// Vue.component('downloadExcel', JsonExcel)
//import excel from "vue-excel-export";
//Vue.use(excel);

// Validation

// import {ValidationProvider} from 'vee-validate/dist/vee-validate.full'
// import {ValidationObserver} from 'vee-validate'

// Vue.component('ValidationProvider',ValidationProvider);
// Vue.component('ValidationObserver',ValidationObserver);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

//multiselect component
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
Vue.component("Multiselect", Multiselect);

// import assets styles
require("@/assets/scss/style.scss");

import DataBase from "./services/DataBase";
Vue.prototype.$database = DataBase;
window.DB = DataBase;

import consts from "./consts.js";
Vue.prototype.$consts = consts;

Vue.config.productionTip = false;

import models from "./models";
Vue.prototype.$models = models;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import './mixins/tableResolution';
