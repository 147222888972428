/**
 * Gönderilen parametrenin tarih uzunluğunda olup olmadığını denetliyor.
 * @param value
 * @returns {boolean}
 */
export default (value) => {
  if (![undefined, null, ''].includes(value)) {
    if (value.length === 10) return true
    else return false
  }
  else return false
}
