import * as functions from '@/mixins/functions'
import i18n from '../../libs/i18n/index'
function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }
export default {
    namespaced: true,
    state: {
      instructor_data: [],  
      room_data:[],
    },
    getters: {
        instructor_data: (state) => state.instructor_data,
        room_data: (state) => state.room_data,
    },
    mutations: {
        instructor_data: (state,payload) => state.instructor_data = payload,
        room_data: (state,payload) => state.room_data = payload,
    },
    
    actions: {
      GET_INSTRUCTOR_DATA({ commit }, { p_company_branch_id }) {
        DB.StudioService.get_studio_instructor_list(
            p_company_branch_id
          ).then((res) => {
            if (!res.is_success || !res.result) return;
              const result = res.result.map((el) => {
                if (el.is_active === '1') {
                    return {
                      ...el,
                      is_active: 'AKTİF'//i18n.locale === 'tr' ? i18n.messages.tr["ACTIVE"] : getKeyByValue(i18n.messages.tr, "AKTİF"),
                    };
                    } else {
                    return {
                      ...el,
                      is_active: 'PASİF'//i18n.locale === 'tr' ? i18n.messages.tr["PASSIVE"] : getKeyByValue(i18n.messages.tr, "PASİF"),
                    };
                  }
              })
              commit('instructor_data', result)
              console.log(result)
          });
    },
  },
        GET_ROOM_DATA({ commit }, { p_company_branch_id }) {
            DB.StudioService.get_studio_room_list(
                p_company_branch_id
              ).then((res) => {
                if (!res.is_success || !res.result) return;
        
                  const result = res.result.map((el) => {
                    if (el.is_active === '1') {
                        return {
                          ...el,
                          is_active: 'AKTİF'//i18n.locale === 'tr' ? i18n.messages.tr["ACTIVE"] : getKeyByValue(i18n.messages.tr, "AKTİF"),
                        };
                        } else {
                        return {
                          ...el,
                          is_active: 'PASİF'//i18n.locale === 'tr' ? i18n.messages.tr["PASSIVE"] : getKeyByValue(i18n.messages.tr, "PASİF"),
                        };
                      }
                  })
                  commit('room_data', result)
                  console.log(result)
              });
        },
    }

