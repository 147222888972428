function getKeyByValue(object, value) {
    return Object.keys(object)
        .find(key => object[key] === value);
}

import i18n from '../../libs/i18n/index';

export default [
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "account-setting.AccountSetting" */ '@/views/account-setting/AccountSetting.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['My Profile'] : getKeyByValue(i18n.messages.tr, 'Profilim'),
            breadcrumb: [
                {
                    to: '/profile',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['My Profile'] : getKeyByValue(i18n.messages.tr, 'Profilim'),
                    active: true
                },
            ],
            isAuthRequired: true
        }
    }
];
