import i18n from '@/libs/i18n'
import moment from 'moment';

export default function (dateValue) {
    const language = i18n.locale;
    if (language === 'tr') {
        let newDate = "";
        if (dateValue !== null || dateValue !== "") {
            newDate = moment(dateValue)
                .locale("tr")
                .format("DD.MM.YYYY")
        }
        else {
            newDate = "01.01.1899"
        }
        return newDate;
    } else {
        return dateValue
    }
}
