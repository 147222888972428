import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'

export default {
    // get_customer_products(p_person_id, p_company_branch_id){
    //     const data = {p_person_id,p_company_branch_id}
    //     return FintechPost.executePostFormData('get_customer_products', 1, data)
    // },

    get_customer_products(p_person_id, p_company_branch_id)
    {
    
   
        const data = {p_person_id,p_company_branch_id}
        return Post.postFormData('api/Customer/GetCustomerProducts', data)
    },
}

