import i18n from '@/libs/i18n'
import moment from 'moment';
export default function (dateValue) {
  const language = i18n.locale;
  if (language === 'tr') {
    let newDate = "";
    if (dateValue !== null || dateValue !== "") {
      console.log(dateValue)
      newDate = moment(dateValue, 'DD.MM.YYYY')
        .locale("tr")
        .format("YYYY-MM-DD")
      console.log(newDate)
    }
    else {
      newDate = "1899-01-01"
    }
    return newDate;
  }
  else {
    return dateValue;
  }
}
