import FintechPost from './BaseConnectCommand/FintechPost'
import Get from './BaseConnectCommand/Get'
import Post from './BaseConnectCommand/Post'

export default {
    // api/Customer/CustomerCreate
    CustomerCreate(data = { CompanyBranchId: '', FirstName: '', LastName: '', Email: '', PhoneNumber: '', Birthday: '', IdentificationNumber, Gender, City_Id, Town_Id, Address, IsAddressesEqual, UserImg: null }) {

        // console.log("Test data"+IdentificationNumber)

        return Post.postFormData('api/Customer/CustomerPersonCreate', data)
    },
    // api/Customer/ExternalCustomerCreate, qr code linki ile müşteri oluşturma  IdentificationNumber
    // ExternalCustomerCreate(CompanyBranchId, FirstName, LastName, IdentificationNumber, Email, PhoneNumber, Birthday, Gender, CountryId, CityId, TownId, Address, UserImg)
    ExternalCustomerCreate(data) {

        //const data = {
        //    CompanyBranchId: CompanyBranchId,
        //    FirstName: FirstName,
        //    LastName: LastName,
        //    IdentificationNumber: IdentificationNumber,
        //    Email: Email,
        //    PhoneNumber: PhoneNumber,
        //    Birthday: Birthday,
        //    Gender: Gender,
        //    CountryId: CountryId,
        //    CityId: CityId,
        //    TownId: TownId,
        //    Address: Address,
        //    UserImg: UserImg
        //}
        console.log(data);

        return Post.postFormData('api/Customer/ExternalCustomerCreate', data)
    },
    get_customer_person_list() {
        const data = {}
        return FintechPost.executePostFormData('get_customer_person_list', 1, data)
    },


    get_customer_person_by_person_id(p_person_id) {

        const data = { personId: p_person_id }
        return Post.postFormData('api/Customer/GetCustomerPerson', data)
    },
    get_person_and_company_branch_person_data(p_company_branch_id) {
        const data = { p_company_branch_id }
        return FintechPost.executePostFormData('get_person_and_company_branch_person_data', 1, data)
    },
    //invite tablosunda is_active false yapar, company_branch_person_role tablosunda is_confirm alanını true check eder
    invite_confirm_check(p_person_invite_id) {
        const data = { p_person_invite_id }
        return FintechPost.executePostFormData('invite_confirm_check', 1, data)
    },
    //person invite id bilgisine göre company name getirmek
    get_company_branch_name_by_person_invite_id(p_person_invite_id) {
        const data = { p_person_invite_id }
        return FintechPost.executePostFormData('get_company_branch_name_by_person_invite_id', 1, data)
    },
    //person invite id bilgisine göre person name getirmek
    get_person_name_by_person_invite_id(p_person_invite_id) {
        const data = { p_person_invite_id }
        return FintechPost.executePostFormData('get_person_name_by_person_invite_id', 1, data)
    },
    //kullanıcı ile ilgili davet bilgileri
    get_person_invite_info(p_person_invite_id) {
        const data = { p_person_invite_id }
        return FintechPost.executePostFormData('get_person_invite_info', 1, data)
    },

    CustomerDashBoardView(p_company_branch_id, p_person_id) {
        const data = { CompanyBranchId: p_company_branch_id, PersonId: p_person_id }
        return Post.postFormData('api/Dashboard/CustomerDashBoardView', data)
    },

    customer_confirm(personInviteId) {
        const data = { personInviteId: personInviteId }
        return Post.postFormData('api/Customer/CustomerConfirm', data)
    },
    CustomerManualActivate(CompanyBranchId, Email, Password, ConfirmPassword) {
        const data = { CompanyBranchId, Mail: Email, Password, ConfirmPassword }
        return Post.postFormData("/api/Customer/CustomerManuelActivate", data);
    }

}