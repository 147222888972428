const language = sessionStorage.getItem('root/language')
import DataBase from '@/services/DataBase'
export default {
    namespaced: true,
    state: {
        token: null,
        userInfo: {
            name: null,
            last_name: null,
            person_id: null,
            email: null,
            menu_role_group_id: null,
            group_name: null,
            company_branch_id: null,
            company_branch_name: null,
            company_branch_person_role_id: null
        },
        userQrCodeInviteCustomer: null,
        optionList: []
    },
    getters: {
        token: (state) => state.token,
        userInfo: (state) => state.userInfo,
        optionList: (state) => state.optionList,
    },
    mutations: {
        token: (state, payload) => state.token = payload,
        userInfo: (state, payload) => state.userInfo = payload,
        optionList: (state, payload) => state.optionList = payload,
    },
    actions: {
        async token(context, payload) {
            let sonuc =0
            const result = await DataBase.AccountService
              .account_login({
                  Email: payload.Email,
                  Password: payload.Password
              })
              .then(async res => {
                  if (res.IsSuccess)
                  {

                    context.commit('token', res.Result.Token);

                    //Kullanıcının üyelik sayısı
                   let userAccountCount=parseInt(res.Result.PersonLoginInfos.length);

                    if(userAccountCount > 0)
                    {
                        //Kullanıcının durumu tek ise (sadece müsteri)
                        if(userAccountCount  == 1)
                        {
                            context.commit('userInfo', res.Result.PersonLoginInfos)
                            sonuc = 1;
                        }
                        else if(userAccountCount > 1) // Kullanıcının durumu birden fazla ise (tesislerde musteri veya musteri ve eğitmen)
                        {
                            context.commit('optionList', res.Result.PersonLoginInfos)

                            sonuc =2;
                        }
                        else
                        {
                            res.Result.PersonLoginInfos=null; // login olabiliyor fakat tesislerdeki durumu pasif ise
                        }
                    }


                    const userData ="";
                      //{
                    //       'id': 1,
                    //     "fullName": "John Doe",
                    //     "username": "johndoe",
                    //     "avatar": "/img/13-small.d796bffd.png",
                    //     "email": "admin@demo.com",
                    //     "role": "admin",
                    //     "ability": [
                    //         {
                    //             "action": "manage",
                    //             "subject": "all"
                    //         }
                    //     ],
                    //     "extras": {
                    //         "eCommerceCartItemsCount": 5
                    //     }
                    // }
                  //  localStorage.setItem('userData',JSON.stringify(userData))
                   // sonuc = true //login.vue 'da işlem yapacağız sonuca göre
                }else
                {
                    sonuc = null //GİRİŞ BAŞARISIZ
                }
            })
            return sonuc
        },
        userInfo(context, payload){
            context.commit('userInfo', payload)
        },
        // async optionList(context, payload) {
        //   //  let sonuc = 0 // 0 hiç tesis yok 1 bir tesis var 2 birden fazla tesis var
        //     // const result = await DataBase.AccountService.get_login_options_list(
        //     //     payload.userEmail
        //     // ).then(async res => {

        //     //     console.log(res.result);
        //     //     //console.log("is_success = " + res.is_success);
        //     //     //console.log(res.result.length);
        //     //     //eğer kullanıcın girebiceği hiç bir tesis yok is_success
        //     //     //res.is_success false yap
        //     //         if(parseInt(res.result.length)>0)
        //     //         {
        //     //             if(res.is_success === true || res.is_success == 1){
        //     //                 if(res.result.length > 1){
        //     //                     context.commit('optionList', res.result)
        //     //                     sonuc = 2
        //     //                 } else {
        //     //                     context.commit('userInfo', res.result[0])
        //     //                     sonuc = 1
        //     //                 }
        //     //             }
        //     //         }
        //     // })
        //   //  return sonuc
        // },
        logout(context,payload){
            sessionStorage.removeItem("auth/token");
            sessionStorage.removeItem("auth/optionList");
            sessionStorage.removeItem("auth/userInfo");
            sessionStorage.removeItem("userData");
            window.location.href = '/login'
        }
    }
}
