/**
 *
 * @param {string} date Denetlenecek tarih
 * @param {object} params Denetleme parametreleri
 * @param {string} params.before - Verilen tarih, bu tarihten önce olmalı. Varsayılan "şimdi".
 * @param {string} params.after - Verilen tarih, bu tarihten sonra olmalı. Varsayılan "1970".
 *
 * @returns boolean
 */
export default (date, params) => {
    const dateP = new Date(date)
    if(!date || !dateP) return false
    if(params?.before && dateP > new Date(params.before)) return false
    if(params?.after && dateP < new Date(params.after)) return false
    return true
}
