import dateformat from 'dateformat'
export default  function(value) {
  try {
    const day = value.substring(8, 10)
    const month = value.substring(5, 7)
    const year = value.substring(0, 4)
    return `${day}.${month}.${year}`
  } catch (err) {
    return ''
  }
}
