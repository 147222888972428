import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    loading:false,
    windowWidth: 0,
    shallShowOverlay: false,
    breadcrumb:"",
  },
  getters: {
    breadcrumb : (state) => state.breadcrumb,
    loading : (state) => state.loading,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    breadcrumb (state, data) {
      state.breadcrumb = data
    },
    loading (state, data) {
      state.loading = data
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
}
