  import axios from 'axios'
// import router from '../../router.js'
import store from '../../store/index'
import MessageBox from "@/services/MessageBox/SweetAlert"


const selectedAPI = require('./API').default
axios.defaults.baseURL = selectedAPI
axios.interceptors.request.use(config => config,
error => {
  // Do something with request error
  // return Promise.reject(error);
})
axios.interceptors.response.use(response => {
  let color = ''
  // WARNİNG İNFO VE ERROR PAGELERİ EKLENECEK
  store.commit('app/loading', false)
  if (response.data.ResultType === 'UNAUTHORIZED') {
    window.location.href = '/pages/not-authorized'
  } else if (response.status === 200 && response.data.IsSuccess === true && response.data.ResultType !== 'LIST' && response.data.ResultType !== 'OBJECT' && response.data.ResultType !== 'RESULT') {
    switch (response.data.ResultType) {
      case 'SUCCESS': color = 'success'; break
      case 'WARNING': color = 'warning'; break
      case 'INFO': color = 'primary'; break
      case 'ERROR': color = 'danger'; break
      default: break
    }
    //MessageBox.OperationSuccessMessage(response.data.Result)
    // bvToast.toast(response.data.Result, {
    //   time: 5000,
    //   solid: true,
    //   variant: color,
    // })
  } else if (response.status === 200 && response.data.IsSuccess === false) {
    // bvToast.toast(response.data.Result, {
    //   time: 8800,
    //   solid: true,
    //   variant: 'warning',
    // })
    //MessageBox.OperationWarningMessage(response.data.Result)
  } else if (response.status === 200 && response.data.ResultType === 'LIST') {
    // eslint-disable-next-line no-param-reassign
    if (response.data.Result === '' || response.data.Result === null) response.data.Result = []
  }
  return response.data
}, error => {
  store.commit('app/loading', false)
  if (error.response.data.ResultType === 'UNAUTHORIZED') {
    window.location.href = '/pages/not-authorized'
  } else if (error.response.status === 401) {
    sessionStorage.removeItem('auth/token');
    sessionStorage.removeItem('auth/optionList');
    sessionStorage.removeItem('auth/userInfo');
    sessionStorage.removeItem('userData');
    window.location.href = '/login'
  } else if (error.response.status === 400) {
    // error.response.data.Result = error.response.data.Result.replace('User Login Denied!!', 'Kullanıcı Adı veya Şifresi Hatalı!!')
    if (error.response.data.ResultType === 'LIST' || error.response.data.ResultType === 'OBJECT' || error.response.data.ResultType === 'RESULT') return error.response.data
    // bvToast.toast(error.response.data.Result, {
    //   time: 8800,
    //   solid: true,
    //   variant: 'warning',
    //   title: 'Uyarı',
    // })
    //MessageBox.OperationWarningMessage(response.data.Result)
  }
  return error.response.data // Promise.reject(error)
})
