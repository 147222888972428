import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {

  // api/Facility/FacilityPersonCreate
  getConditionsByConditionTypes(conditionTypeId)
  {
   const data= {conditionTypeId}
      return Post.postFormData('api/Condition/GetConditionsByConditionTypes',data)
  }
}

