function getKeyByValue(object, value) {
    return Object.keys(object)
        .find(key => object[key] === value);
}

import i18n from '../../libs/i18n/index';
export default [
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home.index" */ '@/views/home/index.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr.Home : getKeyByValue(i18n.messages.tr, 'Anasayfa'),
            breadcrumb: [
                {
                    to: '/home',
                    text: i18n.locale === 'tr' ? i18n.messages.tr.Home : getKeyByValue(i18n.messages.tr, 'Anasayfa'),
                    active: true,
                },
            ],
            isAuthRequired: true,//i18n.messages,
        }

    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import(/* webpackChunkName: "auth.Login" */ '@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: false,
        },
    },
    {
        path: '/selectLogin',
        name: 'select-login',
        component: () => import(/* webpackChunkName: "auth.SelectLogin" */ '@/views/auth/SelectLogin.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: true,
        }
    },
    {
        path: '/ForgotPassword',
        name: 'auth-forgot-password',
        component: () => import(/* webpackChunkName: "auth.ForgotPassword" */ '@/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: false
        }
    },
    {
        path: '/ForgotPassword/:id',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "auth.ResetPassword" */ '@/views/auth/ResetPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },
    {
        path: '/NewPassword',
        name: 'new-password',
        component: () => import(/* webpackChunkName: "auth.NewPassword" */ '@/views/auth/NewPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        }
    },
    {
        path: '/TrainerConfirm/:param1/:param2',
        name: 'trainer-confirm',
        component: () => import(/* webpackChunkName: "auth.TrainerConfirm" */ '@/views/auth/TrainerConfirm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: false
        }
    },
    {
        path: '/CustomerConfirm/:param1/:param2',
        name: 'customer-confirm',
        component: () => import(/* webpackChunkName: "auth.CustomerConfirm" */  '@/views/auth/CustomerConfirm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: false
        }
    },
    {
        path: '/DistributorConfirm/:param1/:param2',
        name: 'distributor-confirm',
        component: () => import(/* webpackChunkName: "auth.DistributorConfirm" */ '@/views/auth/DistributorConfirm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: false
        }
    },
    {
        path: '/FacilityConfirm/:param1/:param2',
        name: 'facility-confirm',
        component: () => import(/* webpackChunkName: "auth.FacilityConfirm" */ '@/views/auth/FacilityConfirm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: false
        }
    },
    {
        path: '/ResendInviteConfirm/:param1/:param2',
        name: 'resend-invite-confirm',
        component: () => import(/* webpackChunkName: "auth.ResendInviteConfirm" */ '@/views/auth/ResendInviteConfirm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: true
        }
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import(/* webpackChunkName: "error.Error404" */ '@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
];
