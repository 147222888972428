function getKeyByValue(object, value) {
  return Object.keys(object)
    .find(key => object[key] === value);
}

import i18n from '../../libs/i18n/index';

export default [
  {
    path: '/permission',
    name: 'permission',
    component: () => import(/* webpackChunkName: "distributor.permission" */ '@/views/distributor/permission.vue'), //Sena was here lsdkjgflsdkgşl
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['User Groups'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Grupları'),
      breadcrumb: [
        {
          text: i18n.locale === 'tr' ? i18n.messages.tr['User Groups'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Grupları'),
          active: true
        }
      ],
      isAuthRequired: true
    },
  },
  {
    path: '/studioCreate',
    name: 'studio_create',
    component: () => import(/* webpackChunkName: "distributor.studio_create" */  '@/views/distributor/studio_create.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Facility Create'] : getKeyByValue(i18n.messages.tr, 'Tesis Oluştur'),
      breadcrumb: [
        {
          to: '/studioList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Facility : getKeyByValue(i18n.messages.tr, 'Tesisler'),
        },
        {
          to: '/studioCreate',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facility Create'] : getKeyByValue(i18n.messages.tr, 'Tesis Oluştur'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
  {
    path: '/studioEdit/:studioID',
    name: 'studio_edit',
    component: () => import(/* webpackChunkName: "distributor.studio_edit" */ '@/views/distributor/studio_edit.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Facility Edit'] : getKeyByValue(i18n.messages.tr, 'Tesis Düzenle'),
      breadcrumb: [
        {
          to: '/studioList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facilities'] : getKeyByValue(i18n.messages.tr, 'Tesisler'),
        },
        {
          to: '/studioEdit',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facility Edit'] : getKeyByValue(i18n.messages.tr, 'Tesis Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },

  },
  {
    path: '/PaymentAdd/:studioID',
    name: 'studio_payment_add',
    component: () => import(/* webpackChunkName: "distributor.studio_payment_add" */ '@/views/distributor/studio_payment_add.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Facility Edit'] : getKeyByValue(i18n.messages.tr, 'Tesis Düzenle'),
      breadcrumb: [
        {
          to: '/studioList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facilities'] : getKeyByValue(i18n.messages.tr, 'Tesisler'),
        },
        {
          to: '/PaymentAdd',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facility Edit'] : getKeyByValue(i18n.messages.tr, 'Tesis Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
  {
    path: '/studioList',
    name: 'studio_list',
    component: () => import(/* webpackChunkName: "distributor.studio_list" */ '@/views/distributor/studio_list.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr.Facilities : getKeyByValue(i18n.messages.tr, 'Tesisler'),
      breadcrumb: [
        {
          to: '/studioList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Facilities : getKeyByValue(i18n.messages.tr, 'Tesisler'),
          active: true,
        },
      ],
      isAuthRequired: true,//i18n.messages,
    },
  },
  {
    path: '/selfStudioCreate',
    name: 'self_studio_create',
    component: () => import(/* webpackChunkName: "distributor.self_studio_create" */ '@/views/distributor/self_studio_create.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      isAuthRequired: false,
    }
  },
  {
    path: '/studioUserList/:studioID',
    name: 'studio_user_list',
    component: () => import(/* webpackChunkName: "distributor.studio_user_list" */ '@/views/distributor/studio_user_list.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Facility Users'] : getKeyByValue(i18n.messages.tr, 'Tesis Kullanıcıları'),
      breadcrumb: [
        {
          to: '/studioList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facilities'] : getKeyByValue(i18n.messages.tr, 'Tesisler'),
        },
        {
          to: '/studioUserList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facility Edit'] : getKeyByValue(i18n.messages.tr, 'Tesis Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true
    },

  },
  {
    path: '/facilityUserCreate/:studioID',
    name: 'facility_user_create',
    component: () => import(/* webpackChunkName: "distributor.facilityUserCreate" */ '@/views/distributor/facilityUserCreate.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Create Facility User'] : getKeyByValue(i18n.messages.tr, 'Tesis Kullanıcısı Oluştur'),
      breadcrumb: [
        {
          to: '/facilityUserCreate',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Create Facility User'] : getKeyByValue(i18n.messages.tr, 'Tesis Kullanıcısı Oluştur'),
        }
      ],
      isAuthRequired: true,
    }
  },
  {
    path: '/facilityUserEdit/:personID/:studioID/:companyBranchPersonRoleId',
    name: 'facility_user_edit',
    //component: () => import("@/views/pages/studio/instructor/studio_instructor_edit.vue"),
    component: () => import(/* webpackChunkName: "distributor.facilityUserEdit" */ '@/views/distributor/facilityUserEdit/index.vue'),
    meta: {
      pageTitle:
        i18n.locale === 'tr'
          ? i18n.messages.tr['Edit Facility User']
          : getKeyByValue(i18n.messages.tr, 'Tesis Kullanıcısı Düzenle'),

      breadcrumb: [
        {
          to: '/studioUserList',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr['Facility Users']
              : getKeyByValue(i18n.messages.tr, 'Tesis Kullanıcıları'),
        },
        {
          to: '/facilityUserEdit',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr['Edit Facility User']
              : getKeyByValue(i18n.messages.tr, 'Tesis Kullanıcısı Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },

  {
    path: '/studioLicence/:studioID',
    name: 'studio_licence',
    component: () => import(/* webpackChunkName: "distributor.studio_licence" */ '@/views/distributor/studio_licence.vue'),
    meta: {

      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Facility Licence'] : getKeyByValue(i18n.messages.tr, 'Tesis Lisans'),

      breadcrumb: [
        {
          to: '/studioList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facilities'] : getKeyByValue(i18n.messages.tr, 'Tesisler'),
        },
        // {
        //   text: store.getters["app/breadcrumb"]
        // },
        {
          to: '/studioUserList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facility Licence'] : getKeyByValue(i18n.messages.tr, 'Tesis Lisans'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },

  },
  {
    path: '/userList/',
    name: 'distributor_list',
    component: () => import(/* webpackChunkName: "distributor.distributorList" */ '@/views/distributor/distributorList.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['User List'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Listesi'),
      breadcrumb: [
        {
          to: '/distributorList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['User List'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Listesi'),
          active: true
        }
      ],
      isAuthRequired: true,
    }
  },
  {
    path: '/userCreate/',
    name: 'distributor_create',
    component: () => import(/* webpackChunkName: "distributor.distributorCreate" */ '@/views/distributor/distributorCreate.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Create User'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Oluştur'),
      breadcrumb: [
        {
          to: '/distributorCreate',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Create User'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Oluştur'),
        }
      ],
      isAuthRequired: true,
    }
  },
  {
    path: '/userEdit/:personID/:companyBranchPersonRoleId',
    name: 'distributorEdit',
    component: () => import(/* webpackChunkName: "distributor.distributorEdit" */ '@/views/distributor/distributorEdit/index.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Edit User'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Düzenle'),
      breadcrumb: [
        {
          to: '/distributorList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['User List'] : getKeyByValue(i18n.messages.tr, 'Kullanıcı Listesi'),
        },
        {
          to: '/distributorEdit/:personID',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Edit : getKeyByValue(i18n.messages.tr, 'Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
];
