import FintechPost from './BaseConnectCommand/FintechPost'

export default {
    get_company_sales_products(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_company_sales_products', 1, data)
    },

    //Sayfalama sorgusu yazmak için kullanacağım
    //Sp den sadece ilgili sayfanın verileri filitrelenmiş bir
    //şekilde gelecek
    get_company_sales_products_filter(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_company_sales_products', 1, data)
    },


    get_products_list_company(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_products_list_company', 1, data)
    },
    trainer_product_is_active_change(p_trainer_product_id, p_is_active){
        const data = {p_trainer_product_id, p_is_active}
        return FintechPost.executePostFormData('trainer_product_is_active_change', 1, data)
    },
    get_product_info(p_trainer_product_id){
        const data = {p_trainer_product_id}
        return FintechPost.executePostFormData('get_product_info', 1, data)
    },


    //GetProductListForExcel
    get_company_sales_products_for_excel(p_company_branch_id,p_start_date,p_end_date){
        const data = {p_company_branch_id, p_start_date, p_end_date}
        return FintechPost.executePostFormData('get_company_sales_products_for_excel', 1, data)
    },


}