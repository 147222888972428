import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import distributorStore from './modules/distributorStore.js'
import studioStore from './modules/studioStore.js'
import root from './modules/root.js'
import auth from './modules/auth.js'
import calendarEdit from './modules/calendarEdit'
import calendarEditv2 from './modules/calendarEditv2'


Vue.use(Vuex)

const myPlugin = store => {
    // Called when store is initialized
    store.subscribe((mutation) => {
        const bannedList = ['' +
        'loading', "app/loading", "initialiseStore", "root/storeLoaded", "root/initialiseStore", "storeLoaded", "app/UPDATE_WINDOW_WIDTH",
            "login/loginDisplay", "login/forgetPasswordDisplay",
            "login/signUpDisplay", "project/finishedList", "project/continueList", "auth/setLogout", "userConfirm/status", "fonlama/isEFT", "fonlama/fonlamaTutari", "fonlama/riskSozlesmeKabul", "auth/backRoutePath", "auth/backRouteName", "distributorStore/licence_data"
        ]
        if (!bannedList.find(a => a === mutation.type)) {
            sessionStorage.setItem(mutation.type, typeof mutation.payload === 'object' ? JSON.stringify(mutation.payload) : mutation.payload)
        }

    })
}


export default new Vuex.Store({
    state: {
        storeLoaded: false
    },
    getters: {
        storeLoaded: (state) => state.storeLoaded
    },
    mutations: {
        storeLoaded: (state, payload) => state.storeLoaded = payload,
        initialiseStore(state) {
            const stateVariables = Object.keys(state)
            stateVariables.forEach(stateKey => {
                try {
                    const moduleStateKeys = Object.keys(state[stateKey]);
                    moduleStateKeys.forEach(childStateKey => {
                        const temp = sessionStorage.getItem(stateKey + "/" + childStateKey);
                        if (temp) {
                            try {
                                (state[stateKey])[childStateKey] = JSON.parse(temp);
                            } catch {
                                (state[stateKey])[childStateKey] = temp;
                            }
                        }
                    })
                } catch (err) {
                    if (sessionStorage.getItem(stateKey)) {
                        try {
                            state[stateKey] = JSON.parse(sessionStorage.getItem(stateKey))
                        } catch (err) {
                            state[stateKey] = sessionStorage.getItem(stateKey)
                        }
                    }
                }
            })
        },
    },
    modules: {
        app,
        appConfig,
        verticalMenu,
        distributorStore,
        studioStore,
        root,
        auth,
        calendarEdit,
        calendarEditv2
    },
    strict: process.env.DEV,
    plugins: [myPlugin]
})
