import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {

  // api/Facility/FacilityPersonCreate
  FacilityPersonCreate(data= {CompanyBranchId:'',FirstName:'',LastName:'',Email:'',PhoneNumber:'',Birthday:'',Gender:0, UserImg: ''}){
    //  console.log(data);
      return Post.postFormData('api/Facility/FacilityPersonCreate',data)
  },
  get_facility_person_list(){
      const data = {}
      return FintechPost.executePostFormData('get_facility_person_list', 1, data)
  },
  get_confirm_by_branch_person_role_id(p_company_branch_person_role_id){
    const data = {p_company_branch_person_role_id}
    return FintechPost.executePostFormData('get_confirm_by_branch_person_role_id', 1, data)

  },
  get_facility_user_data(p_company_branch_id){
    const data = {p_company_branch_id}
    return FintechPost.executePostFormData('get_facility_user_data', 1, data)
  },


  /**
  * 16.04.2022
  * Şenel YILDIZ
  * Studioya ait olan müşterileri listler
  */
  list_company_customers(p_company_branch_id)
  { 
    const data = {p_company_branch_id}
    return FintechPost.executePostFormData('list_company_customers', 1, data)

  },

  facility_confirm(personInviteId)
  {
    const data= {personInviteId:personInviteId}
    return Post.postFormData('api/Facility/FacilityConfirm',data)
  },

}

