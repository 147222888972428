import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {

    record_time_table(p_company_branch_id, p_subject_title, p_description="", p_trainer_id, p_activity_id, p_start_date, p_end_date, p_room_id, p_capacity, p_participants_list){
        const data = {p_company_branch_id, p_subject_title, p_description, p_trainer_id,p_activity_id, p_start_date, p_end_date, p_room_id, p_capacity, p_participants_list}
        return FintechPost.executePostFormData('record_time_table', 1, data)
    },

    update_time_table(p_time_table_id, p_company_branch_id, p_subject_title, p_description="", p_trainer_product_id, p_trainer_id,p_activity_id, p_start_date, p_end_date, p_room_id, p_capacity){
        const data = {p_time_table_id, p_company_branch_id, p_subject_title, p_description, p_trainer_product_id, p_trainer_id,p_activity_id, p_start_date, p_end_date, p_room_id, p_capacity}
        return FintechPost.executePostFormData('update_time_table', 1, data)
    },

    get_scheduler_list_by_trainer(p_company_branch_id, p_trainer_id){
        const data = {p_company_branch_id, p_trainer_id}
        return FintechPost.executePostFormData('get_scheduler_list_by_trainer', 1, data)
    },

    get_scheduler_list(p_company_branch_id, p_trainer_id){
        const data = {p_company_branch_id, p_trainer_id}
        return FintechPost.executePostFormData('get_scheduler_list', 1, data)
    },

    get_appointment_calendar_list(CompanyBranchId, TrainerId,ActivityId,StartDate,EndDate)
    {
        const data = {CompanyBranchId, TrainerId,ActivityId,StartDate,EndDate}
        // console.log(p_company_branch_id)
        // console.log(person_id)
       // const data = {company_branch_id, person_id}
        return Post.postFormData('api/Appointment/GetAppointmentCalendars', data)
    },

    // get_customer_products(p_person_id, p_company_branch_id)
    // {
    //     const data = {p_person_id,p_company_branch_id}
    //     return Post.postFormData('api/Customer/GetCustomerProducts', data)
    // },

    get_activity_company_branch(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_activity_company_branch', 1, data)
    },
    get_time_table_record_details(p_time_table_id, p_company_branch_id){
        const data = {p_time_table_id, p_company_branch_id}
        return FintechPost.executePostFormData('get_time_table_record_details', 1, data)
    },
    delete_time_table_record(p_time_table_id){
        const data = {p_time_table_id}
        return FintechPost.executePostFormData('delete_time_table_record', 1, data)
    }
}
