import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
//routes
import calendar from './routes/calendar'
import customer from './routes/customer'
import distributor from './routes/distributor'
import facility from './routes/facility'
import auth from './routes/auth'
import accountSetting from './routes/account-setting'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history', //history mode dan hash mode'a geçildi
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'auth-login' }
    },
    ...customer,
    ...calendar,
    ...facility,
    ...distributor,
    ...auth,
    ...accountSetting,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {

  if (store.getters.storeLoaded === false) {
    store.commit('initialiseStore')
    store.commit('storeLoaded', true)
  }
  if (to.path === '/' && !store.getters['auth/token']) {
    next({
      name: 'auth-login'
    })
  }

  if (to.path === '/login' && store.getters['auth/token']) {
    next({
      name: 'home'
    })
  }

  if (to.matched.some(record => record.meta.isAuthRequired && !store.getters['auth/token'])) {
    next({
      name: 'auth-login',
      query: {
        'path': to.fullPath,
        'name': to.name
      }
    })
  }

  next()

  // if (!store.getters['auth/userInfo'].company_branch_id //userInfo boşsa kıstas olarak company_branch_id aldım
  //   &&
  //   store.getters['auth/optionList'].length > 1 //ve optionList length büyük 1 se birden çok seçenek var ama seçilmemiştir
  //   &&
  //     to.path != '/selectLogin'
  //     && to.name != 'customer-confirm' && from.name != 'customer-confirm'
  //     && to.name != 'distributor-confirm' && from.name != 'distributor-name'
  //     && to.name != 'facility-confirm' && from.name != 'facility-confirm'
  //     && to.name != 'trainer-confirm' && from.name != 'trainer-confirm'
  //     && to.name != 'resend-invite-confirm' && from.name != 'resend-invite-confirm') {
  //     next({ name: 'select-login' })
  // }
  // if (to.matched.some(record => record.meta.isAuthRequired && !store.getters['auth/token'])) {
  //     next({
  //         name: 'auth-login',
  //         query: {
  //             'path': to.fullPath,
  //             'name': to.name
  //         }
  //     })
  // } else if (to.matched.some(record => !record.meta.isAuthRequired && store.getters['auth/token']
  //   && to.name != 'customer-public-invite'
  //   && to.name != 'reset-password'
  //   && to.name != 'new-password')) {
  //     next('home')
  // } else {
  //     next()
  // }
})

router.afterEach((to, from, next) => {

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
