import FintechPost from './BaseConnectCommand/FintechPost'
import Get from './BaseConnectCommand/Get'
import Post from './BaseConnectCommand/Post'

export default {
    person_get_by_email(p_company_branch_id, p_email){
        const data = {p_company_branch_id, p_email}
        return FintechPost.executePostFormData('person_get_by_email',1,data)
    },
    get_person_by_person_id(p_person_id){
        const data = {p_person_id}
        return FintechPost.executePostFormData('get_person_by_person_id',1,data)
    },
    get_person_by_trainer_id(p_trainer_id, p_company_branch_id){
        const data = {p_trainer_id, p_company_branch_id}
        return FintechPost.executePostFormData('get_person_by_trainer_id',1,data)
    },
    person_update_by_person_id(p_person_id, p_name,p_last_name,p_phone_number,p_birthday,p_gender,p_person_image){
        const data = {p_person_id, p_name,p_last_name,p_phone_number,p_birthday,p_gender,p_person_image}
        return FintechPost.executePostFormData('person_update_by_person_id',1,data)
    },
    get_person_list(){
        const data = {}
        return FintechPost.executePostFormData('get_person_list',1,data)
    },
    get_customer_activity_status(p_company_branch_id,p_person_id){
        const data = {p_company_branch_id, p_person_id}
        return FintechPost.executePostFormData('get_customer_activity_status',1,data)

    },
    get_studio_room_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_room_list',1,data)
    },
    room_create(p_company_branch_id,p_name){
        const data = {p_company_branch_id, p_name}
        return FintechPost.executePostFormData('room_create',1,data)
    },
    room_update(p_room_id,p_company_branch_id,p_name){
        const data = {p_room_id,p_company_branch_id, p_name}
        return FintechPost.executePostFormData('room_update',1,data)
    },
    get_studio_instructor_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_instructor_list',1,data)
    },
    get_trainer_product_list(p_person_id,p_company_branch_id){
        const data = {p_person_id,p_company_branch_id}
        return FintechPost.executePostFormData('get_trainer_product_list',1,data)
    },
    get_product_by_product_id(p_trainer_product_id, p_company_branch_id){
        const data = {p_trainer_product_id, p_company_branch_id}
        return FintechPost.executePostFormData('get_product_by_product_id',1,data)
    },
    product_isactive_change(p_trainer_product_id, p_is_active){
        const data = {p_trainer_product_id, p_is_active}
        return FintechPost.executePostFormData('product_isactive_change',1,data)
    },
    instructor_create(p_company_branch_id,p_name,p_last_name,p_email,p_phone_number,p_birthday,p_gender,p_person_image){
        const data = {p_company_branch_id,p_name,p_last_name,p_email,p_phone_number,p_birthday,p_gender,p_person_image}
        return FintechPost.executePostFormData('instructor_create',1,data)
    },
    instructor_update(p_instructor_id,p_company_branch_id,p_name,p_last_name){
        const data = {p_instructor_id,p_company_branch_id, p_name, p_last_name}
        return FintechPost.executePostFormData('instructor_update',1,data)
    },
    get_company_person(p_company_branch_id, p_person_id) {
        const data = { p_company_branch_id, p_person_id }
        return FintechPost.executePostFormData("get_company_person", 1, data);
    },
    get_company_branch_role(p_company_branch_id, p_person_id){
        const data = {p_company_branch_id, p_person_id}
        return FintechPost.executePostFormData("get_company_branch_role", 1, data);
    },
    studio_instructor_deactive(p_trainer_id,p_is_active){
        const data = {p_trainer_id,p_is_active}
        return FintechPost.executePostFormData('studio_instructor_deactive',1,data)
    },
    //person id bilgisi ile sadece trainer ismini getirmek için
    get_trainer_name(p_person_id){
        const data = {p_person_id}
        return FintechPost.executePostFormData('get_trainer_name',1,data)
    },
    //person id bilgisi ile trainer bilgilerini döndürür
    get_trainer_info(p_person_id){
        const data = {p_person_id}
        return FintechPost.executePostFormData('get_trainer_info',1,data)
    },
    //trainer product oluşturmak
    trainer_product_create(p_company_branch_id,p_trainer_id, p_activity_id, p_title, p_description, p_price, p_session,p_image,p_participants_count,p_expiry_month,p_expiry_day)
    {
        const data = {p_company_branch_id,p_trainer_id,p_activity_id,p_title,p_description,p_price,p_session,p_image,p_participants_count,p_expiry_month,p_expiry_day}

        return FintechPost.executePostFormData('trainer_product_create',1,data)
    },
    //trainer product güncellemek
    trainer_product_update(p_company_branch_id, p_trainer_product_id, p_trainer_id, p_activity_id, p_title, p_description, p_price, p_session, p_image, p_participants_count,p_expiry_month,p_expiry_day){
        const data = {p_company_branch_id, p_trainer_product_id, p_trainer_id, p_activity_id, p_title, p_description, p_price, p_session, p_image, p_participants_count,p_expiry_month,p_expiry_day}
        return FintechPost.executePostFormData('trainer_product_update',1,data)
    },
    person_create(p_company_branch_id,p_name,p_last_name,p_email,p_phone_number,p_birthday,p_gender,p_person_image){
        const data = {p_company_branch_id, p_name,p_last_name,p_email,p_phone_number,p_birthday,p_gender,p_person_image}
        return FintechPost.executePostFormData('person_insert',1,data)
    },
    //Studio  tilperini getir GX,PT
    get_activite_types(activityName,activityTypes){
        const data = {activityName}
        return FintechPost.executePostFormData('get_activite_types',1,data)
    },

    get_activity_name(p_activity_id){
        const data = {p_activity_id}
        return FintechPost.executePostFormData('get_activity_name',1,data)
    },

    //activity_create(p_name,p_activity_time,p_activity_types,p_company_branch_id,p_activity_color){
    //    const data = {p_name,p_activity_time,p_activity_types,p_company_branch_id,p_activity_color}
    //    return FintechPost.executePostFormData('activity_create',1,data)
    //},

    activity_create(p_name, p_activity_time, p_company_branch_id, p_activity_color) {
        const data = { ActivityName: p_name, ActivityTime: p_activity_time, CompanyBranchId: p_company_branch_id, Color: p_activity_color }
        return Post.postFormData('/api/Activity/ActiveCreate', data)
    },

    activity_update(p_activity_id, p_name, p_activity_time, p_company_branch_id, p_activity_color) {

        const data = { ActivityId: p_activity_id,  ActivityName: p_name, ActivityTime: p_activity_time, CompanyBranchId: p_company_branch_id, Color: p_activity_color }

        return Post.postFormData('/api/Activity/ActiveUpdate', data)
    },






    studio_room_deactive(p_room_id,p_is_active){
        const data = {p_room_id,p_is_active}
        return FintechPost.executePostFormData('studio_room_deactive',1,data)
    },
        /**
         * 27.08.2021
         * Şenel YILDIZ
         * Studio y activitelerini ve activite tiplerini günncele
        */
    //activity_update(p_activity_id,p_activity_name,p_activity_time,p_activity_color,p_activity_types){
    //    const data = {p_activity_id,p_activity_name,p_activity_time,p_activity_color,p_activity_types}
    //    return FintechPost.executePostFormData('activity_update',1,data)
    //},

    activity_update_new(p_activity_id, p_activity_name, p_activity_time, p_activity_color){
        const data = {p_activity_id, p_activity_name, p_activity_time, p_activity_color}
        return FintechPost.executePostFormData('activity_update_new', 1, data)
    },

    activity_type_get_list(){
        const data = {}
        return FintechPost.executePostFormData('activity_type_get_list',1,data)
    },

    activity_get_list(p_company_branch_id,p_is_active)
    {
        const data = {p_company_branch_id,p_is_active}
        return FintechPost.executePostFormData('activity_get_list',1,data)
    },

    /**
     * Yazar Şenel YILDIZ
     * Tarih 22.08.2022
     * Aktivite listesinde Aktif Aktiviteler Pasif Aktiviteler ve Tüm Aktiviteler doğru çalışmıyor.
     * activity_get_list  sp sinde isActive=true eklenmiş
     * muhtemelen sp başka yerde de kullanılıyor.
     * ActivityLList için yeni endpoint yazdım
     * @param {Distributor Id} p_company_branch_id
     * @param {p_is_active =0 tümü , p_is_active=1 Aktif olanlar p_is_active=2 //Pasif olanlar} p_is_active
     * @returns Aktivite listesi
     */
     GetActivities(p_company_branch_id,p_is_active){
        const data = {CompanyBranchId:p_company_branch_id,IsActive:p_is_active}
        
        return Post.postFormData('/api/Activity/GetActivities', data)
      },


    get_customer_appointment_list(p_company_branch_id,p_start_date,p_end_date)
    {
        const data = {p_company_branch_id,p_start_date,p_end_date}
        return FintechPost.executePostFormData('get_customer_appointment_list',1,data)
    },



    /**
     *
     * @param {fiyat bilgileri çekilecek olan Activitenin Id si} p_activity_id
     * @returns
     */
    activity_price_list_get_by_id(p_activity_id)
    {
        const data = {p_activity_id}
        return FintechPost.executePostFormData('activity_price_list_get_by_id',1,data)
    },

        /**
        * 27.08.2021
        * Şenel YILDIZ
        * Studioya ait olan Activiteyi activite tipleri ile birlikte getir
        * Örnek Activite Zumba Tipi GX - Activite Combat Tipi GX ve PT
        */
     activity_get_by_id(p_company_branch_id,p_activity_id,p_is_active){
        const data = {p_company_branch_id,p_activity_id,p_is_active}
        return FintechPost.executePostFormData('activity_get_by_id',1,data)
    },

    /**
    * 30.08.2021
    * Şenel YILDIZ
    * Studioya ait olan Activiteyi actif yada pasif yapar
    */
    activity_isactive_change(p_activity_id,p_is_active)
    {
        const data = {p_activity_id,p_is_active}
        return FintechPost.executePostFormData('activity_isactive_change',1,data)
    },

     /**
     * Yazar Şenel YILDIZ
     * Tarih 22.08.2022
     * Aktivite listesinde Aktif Pasif durumunu değiştirir

     * @param {Distributor Id} p_company_branch_id
     * @param {p_is_active =0 False , p_is_active=1 True}
     * @returns true yada false
     */
      ActivityIsActiveChange(p_activity_id,p_is_active){
        const data = {ActivityId:p_activity_id,IsActive:p_is_active}


          return Post.postFormData('/api/Activity/IsActiveChange', data)
      },






    /**
     * Akviteye tanımlanmıs olan tipleri listeler
     * @param {tipleri listelenecek olan Activitenin Id numarası} p_activity_id
     * @returns Geriye dönen json formatında Activite Tip listesi
     */
    activity_detail_type_get_list(p_activity_id)
    {
        const data = {p_activity_id}
        return FintechPost.executePostFormData('activity_detail_type_get_list',1,data)
    },

    /**
     * Activitelerin Tiplerine göre fiyatlanın belirlenmesi
     * @param {Fiyatı eklenecek olan Activite ve Tipi COMBAT=> GX} p_activity_detail_id
     * @param {Fiyat numerik 18,4} p_price
     * @param {Fiyatın gereçrli olacağı tarih bugünün tarihinden küçük olamaz} p_price_start_date
     * @param {kaydı gerçekleştiren kullanıcı} p_executed_user_id
     * @returns Json data
     */
    activity_price_create(p_activity_detail_id,p_price,p_price_start_date,p_executed_user_id)
    {
        const data = {p_activity_detail_id,p_price,p_price_start_date,p_executed_user_id}
        return FintechPost.executePostFormData('activity_price_create',1,data)
    },


    get_price_list_product(p_trainer_product_id){
        const data = {p_trainer_product_id}
        return FintechPost.executePostFormData('get_price_list_product',1,data)
    },
    get_trainer_product_name(p_trainer_product_id){
        const data = {p_trainer_product_id}
        return FintechPost.executePostFormData('get_trainer_product_name',1,data)
    },
    get_company_branch_person(p_person_id,p_company_branch_id){
        const data = {p_person_id,p_company_branch_id}
        return FintechPost.executePostFormData('get_company_branch_person',1,data)
    },
    instructor_create_company_branch(p_company_branch_id,p_person_id){
        const data = {p_company_branch_id,p_person_id}
        return FintechPost.executePostFormData('instructor_create_company_branch',1,data)
    },
    get_company_branch_person_fe_role(p_company_branch_person_id){
        const data = {p_company_branch_person_id}
        return FintechPost.executePostFormData('get_company_branch_person_fe_role',1,data)
    },
    instructor_create_role(p_company_branch_person_id){
        const data = {p_company_branch_person_id}
        return FintechPost.executePostFormData('instructor_create_role',1,data)
    },
    instructor_only_invite(p_company_branch_person_id){
        const data = {p_company_branch_person_id}
        return FintechPost.executePostFormData('instructor_only_invite',1,data)
    },
    user_get_by_email(p_email){
        const data = {p_email}
        return FintechPost.executePostFormData('user_get_by_email',1 , data)
    },
    get_studio_customer_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_customer_list', 1, data)
    },
    //customer is_confirm olmuş mu
    customer_is_confirm_check(p_person_id, p_company_branch_id){
        const data = {p_person_id, p_company_branch_id}
        return FintechPost.executePostFormData('customer_is_confirm_check', 1, data)
    },

    /**StudioDoor Servisleri */
    StudioDoorCreate(StudioDoorCreate){
        return Post.postFormData('/api/StudioDoor/StudioDoorCreate', StudioDoorCreate)
    },

      /**StudioDoor Servisleri */
    GetStudioDoor(p_company_brancId)
    {
        const data = {CompanyBrancId:p_company_brancId}
        return Post.postFormData('/api/StudioDoor/GetStudioDoor', data)
    },
       /**StudioDoor Servisleri */
    StudioDoorOpen(company_branch_id,time_table_id, time_table_detail_id){
        const data = {CompanyBranchId:company_branch_id,TimeTableId:time_table_id,TimeTableDetailId:time_table_detail_id}
        return Post.postFormData('/api/StudioDoor/StudioDoorOpen',data)
    },
}
