function getKeyByValue(object, value) {
  return Object.keys(object)
    .find(key => object[key] === value);
}

import i18n from '../../libs/i18n/index';

export default [
  {
    path: '/customerList',
    name: 'customerList',
    component: () => import(/* webpackChunkName: "facility.customer.customerList" */ '@/views/facility/customer/customerList.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr.Customers : getKeyByValue(i18n.messages.tr, 'Müşteriler'),
      breadcrumb: [
        {
          to: '/customerList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Customers : getKeyByValue(i18n.messages.tr, 'Müşteriler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
  {
    path: '/customerCreate',
    name: 'customerCreate',
    component: () => import(/* webpackChunkName: "facility.customer.customerCreate" */ '@/views/facility/customer/customerCreate.vue'),
    meta: {

      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Create Customer'] : getKeyByValue(i18n.messages.tr, 'Müşteri Tanımlama'),

      breadcrumb: [
        {
          to: '/customerList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Customers : getKeyByValue(i18n.messages.tr, 'Müşteriler'),
        },
        {
          to: '/customerCreate',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Create : getKeyByValue(i18n.messages.tr, 'Oluştur'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
  {
    path: '/customerEdit/:personID',
    name: 'customerEdit',
    component: () => import(/* webpackChunkName: "facility.customer.customerEdit" */ '@/views/facility/customer/customerEdit/index.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Edit Customer'] : getKeyByValue(i18n.messages.tr, 'Müşteri Düzenle'),
      breadcrumb: [
        {
          to: '/customerList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Customers : getKeyByValue(i18n.messages.tr, 'Müşteriler'),
        },
        {
          to: '/customerEdit/:personID',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Edit : getKeyByValue(i18n.messages.tr, 'Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },

  {
    path: '/customerProducts/:personID',
    name: 'customerProducts',
    component: () => import(/* webpackChunkName: "facility.customer.customerProducts" */ '@/views/facility/customer/customerProducts.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Customer Activities'] : getKeyByValue(i18n.messages.tr, 'Satın Alma Geçmişi'),
      breadcrumb: [
        {
          to: '/shop',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Customer Activities'] : getKeyByValue(i18n.messages.tr, 'Satın Alma Geçmişi'),
          active: true
        }
      ],
      isAuthRequired: true,
    }
  },

  {
    path: '/StudioRoom',
    name: 'studio_room_list',
    component: () => import(/* webpackChunkName: "facility.room.studio_room_list" */ '@/views/facility/room/studio_room_list.vue'),
    meta: {

      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Facility Rooms'] : getKeyByValue(i18n.messages.tr, 'Tesis Salonları'),

      breadcrumb: [
        {
          to: '/studioRoom',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Facility Rooms'] : getKeyByValue(i18n.messages.tr, 'Tesis Salonları'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
  {
    path: '/CreateRoomCapacity/:studioID/:roomID/:roomName',
    name: 'CreateRoomCapacity',
    component: () => import(/* webpackChunkName: "facility.room.create_room_capacity" */ '@/views/facility/room/create_room_capacity.vue'),
    meta: {

      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Create Room Capacity'] : getKeyByValue(i18n.messages.tr, 'Salon Kapasitesi Oluştur'),

      breadcrumb: [
        {
          to: '/studioRoom',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Rooms : getKeyByValue(i18n.messages.tr, 'Salonlar'),
        },
        {
          to: '/CreateRoomCapacity',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Room Edit'] : getKeyByValue(i18n.messages.tr, 'Salon Düzenle'),
          active: true
        }
      ],
      isAuthRequired: true,
    },
  },

  {
    path: '/studioInstructors/',
    name: 'studio_instructors_list',
    component: () => import(/* webpackChunkName: "facility.instructor.studio_instructor_list" */ '@/views/facility/instructor/studio_instructor_list.vue'),
    meta: {
      pageTitle:
        i18n.locale === 'tr'
          ? i18n.messages.tr.Trainers
          : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
      breadcrumb: [
        {
          to: '/studioInstructors',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr.Trainers
              : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },

  {
    path: '/instructorCreate',
    name: 'instructorCreate',
    component: () =>
      import(/* webpackChunkName: "facility.instructor.studio_add_new_instructor" */ '@/views/facility/instructor/studio_add_new_instructor.vue'),
    meta: {
      pageTitle:
        i18n.locale === 'tr'
          ? i18n.messages.tr['Create Instructor']
          : getKeyByValue(i18n.messages.tr, 'Eğitmen Tanımlama'),

      breadcrumb: [
        {
          to: '/studioInstructors',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr.Trainers
              : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
        },
        {
          to: '/instructorCreate',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr.Create
              : getKeyByValue(i18n.messages.tr, 'Oluştur'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },
  {
    path: '/instructorEdit/:personID',
    name: 'instructorEdit',
    component: () => import(/* webpackChunkName: "facility.instructor.instructorEdit" */ '@/views/facility/instructor/instructorEdit/index.vue'),
    meta: {
      pageTitle:
        i18n.locale === 'tr'
          ? i18n.messages.tr['Edit Trainer']
          : getKeyByValue(i18n.messages.tr, 'Eğitmen Düzenleme'),

      breadcrumb: [
        {
          to: '/studioInstructors',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr.Trainers
              : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
        },
        {
          to: '/instructorEdit',
          text:
            i18n.locale === 'tr'
              ? i18n.messages.tr.Edit
              : getKeyByValue(i18n.messages.tr, 'Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true,
    },
  },

  {
    path: '/AddProductToTrainer/:trainerID',
    name: 'AddProductToTrainer',
    component: () => import(/* webpackChunkName: "facility.instructor.add_product_to_trainer" */  '@/views/facility/instructor/add_product_to_trainer.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Assign Product to Trainer'] : getKeyByValue(i18n.messages.tr, 'Eğitmene Ürün Ata'),
      breadcrumb: [
        {
          to: '/studioInstructors',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Trainers : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
        },
        {
          to: '/AddProductToTrainer',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Assign Product to Trainer'] : getKeyByValue(i18n.messages.tr, 'Eğitmene Ürün Ata'),
          active: true
        },
      ],
      isAuthRequired: true
    }
  },
  {
    path: '/StudioDoorCreate',
    name: 'StudioDoorCreate',
    component: () => import(/* webpackChunkName: "facility.instructor.studioDoorCrete" */ '@/views/facility/door/studioDoorCrete.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Define Entrance Door'] : getKeyByValue(i18n.messages.tr, 'Define Entrance Door'),
      // breadcrumb: [
      //   {
      //     to: '/studioDoorCrete',
      //     text: i18n.locale === 'tr' ? i18n.messages.tr.Trainers : getKeyByValue(i18n.messages.tr, 'Define Entrance Door'),
      //   }
      // ],
      isAuthRequired: true
    }
  },





  {
    path: '/InstructorProducts/:trainerID',
    name: 'InstructorProducts',
    component: () => import(/* webpackChunkName: "facility.instructor.instructor_products" */ '@/views/facility/instructor/instructor_products.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Instructor Products'] : getKeyByValue(i18n.messages.tr, 'Eğitmen Ürünleri'),
      breadcrumb: [
        {
          to: '/studioInstructors',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Trainers : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
        },
        {
          to: '/InstructorProducts',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Instructor Products'] : getKeyByValue(i18n.messages.tr, 'Eğitmen Ürünleri'),
          active: true
        },
      ],
      isAuthRequired: true,
    }
  },

  {
    path: '/EditProduct/:productID',
    name: 'EditProduct',
    component: () => import(/* webpackChunkName: "facility.instructor.edit_product" */ '@/views/facility/instructor/edit_product.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Edit Product'] : getKeyByValue(i18n.messages.tr, 'Ürünü Düzenle'),
      breadcrumb: [
        {
          to: '/studioInstructors',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Trainers : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
        },
        {
          to: '/EditProduct',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Edit Product'] : getKeyByValue(i18n.messages.tr, 'Ürünü Düzenle'),
          active: true
        },
      ],
      isAuthRequired: true
    }
  },

  {
    path: '/productPriceList/:trainerID/:productID',
    name: 'productPriceList',
    component: () => import(/* webpackChunkName: "facility.instructor.instructor_products_price_list" */ '@/views/facility/instructor/instructor_products_price_list.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Price History'] : getKeyByValue(i18n.messages.tr, 'Fiyat Geçmişi'),
      breadcrumb: [
        {
          to: '/studioInstructors',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Trainers : getKeyByValue(i18n.messages.tr, 'Eğitmenler'),
        },
        {
          to: '/productPriceList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Price History'] : getKeyByValue(i18n.messages.tr, 'Fiyat Geçmişi'),
          active: true
        },
      ],
      isAuthRequired: true,
    }
  },

  {
    path: '/activityList',
    name: 'activityList',
    component: () => import(/* webpackChunkName: "facility.activity.activityList" */ '@/views/facility/activity/activityList.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr.Activities : getKeyByValue(i18n.messages.tr, 'Aktiviteler'),
      breadcrumb: [
        {
          to: '/activityList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Activities : getKeyByValue(i18n.messages.tr, 'Aktiviteler'),
          active: true,
        },
      ],
      isAuthRequired: true
    },
  },

  {
    path: '/activityEdit/:activityID',
    name: 'activityEdit',
    component: () => import(/* webpackChunkName: "facility.activity.activityEdit" */ '@/views/facility/activity/activityEdit.vue'),
    meta: {
      // pageTitle: ((x) => { return Math.random(0,x)})(),
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Edit'] : getKeyByValue(i18n.messages.tr, 'Düzenle'),
      breadcrumb: [
        {
          to: '/activityList',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Activities'] : getKeyByValue(i18n.messages.tr, 'Aktiviteler'),
        },
        {
          to: '/activityEdit',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Edit'] : getKeyByValue(i18n.messages.tr, 'Düzenle'),
          active: true,
        },
      ],
      isAuthRequired: true
    },
  },

  {
    path: '/activityCreate',
    name: 'activityCreate',
    component: () => import(/* webpackChunkName: "facility.activity.activityCreate" */ '@/views/facility/activity/activityCreate.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Activity Create'] : getKeyByValue(i18n.messages.tr, 'Aktivite Oluştur'),
      breadcrumb: [
        {
          to: '/activityList',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Activities : getKeyByValue(i18n.messages.tr, 'Aktiviteler'),
        },
        {
          to: '/activityCreate',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Activity Create'] : getKeyByValue(i18n.messages.tr, 'Aktivite Oluştur'),
          active: true,
        },
      ],
      isAuthRequired: true
    },
  },

  //trainer calendar
  {
    path: '/trainerScheduler',
    name: 'trainer-scheduler',
    component: () => import(/* webpackChunkName: "facility.calendar.trainerCalendarEdit" */ '@/views/facility/calendar/trainerCalendarEdit.vue'),
    meta: {
      isAuthRequired: true
    },
  },

  {
    path: '/AppointmentCalendar',
    name: 'appointment-calendar',
    component: () => import('@/views/facility/calendar/AppointmentCalendar.vue'),
    meta: {
      isAuthRequired: true
    },
  },




  //bloklu zamandan önce ilgili eğitmeni seç
  {
    path: '/selectTrainer',
    name: 'selectTrainer',
    component: () => import(/* webpackChunkName: "facility.calendar.selectTrainer" */ '@/views/facility/calendar/selectTrainer.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Select Trainer'] : getKeyByValue(i18n.messages.tr, 'Eğitmen Seç'),
      breadcrumb: [
        {
          to: '/selectTrainer',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Select Trainer'] : getKeyByValue(i18n.messages.tr, 'Eğitmen Seç'),
          active: true
        }
      ],
      isAuthRequired: true
    }
  },

  // //müsaitlik durumu ayarla
  // {
  //   path: '/avaibleTime/:trainerID',
  //   name: 'avaibleTime',
  //   component: () => import('@/views/facility/calendar/setAvaibleTime.vue'),
  //   meta: {
  //     pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Set Your Availability'] : getKeyByValue(i18n.messages.tr, 'Müsaitliği Ayarla'),
  //     breadcrumb: [
  //       {
  //         to: '/avaibleTime',
  //         text: i18n.locale === 'tr' ? i18n.messages.tr['Set Your Availability'] : getKeyByValue(i18n.messages.tr, 'Müsaitliği Ayarla'),
  //         active: true,
  //       },
  //     ],
  //     isAuthRequired: true,
  //   }
  // },

  //tüm ürünler liste sayfası
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "facility.product.productList" */ '@/views/facility/product/productList'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr.Products : getKeyByValue(i18n.messages.tr, 'Ürünler'),
      breadcrumb: [
        {
          to: '/products',
          text: i18n.locale === 'tr' ? i18n.messages.tr.Products : getKeyByValue(i18n.messages.tr, 'Ürünler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },

  //tesisteki satılan ürünler
  {
    path: '/productsSold',
    name: 'products-sold',
    component: () => import(/* webpackChunkName: "facility.product.productsSold" */ '@/views/facility/product/productsSold.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Products Sold'] : getKeyByValue(i18n.messages.tr, 'Satılan Ürünler'),
      breadcrumb: [
        {
          to: '/products',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Products Sold'] : getKeyByValue(i18n.messages.tr, 'Satılan Ürünler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },


  //tesisteki satılan ürünler
  {
    path: '/productsSoldPg',
    name: 'products-sold-pg',
    component: () => import(/* webpackChunkName: "facility.product.productsSoldPg" */ '@/views/facility/product/productsSoldPg.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Products Sold'] : getKeyByValue(i18n.messages.tr, 'Satılan Ürünler'),
      breadcrumb: [
        {
          to: '/products',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Products Sold'] : getKeyByValue(i18n.messages.tr, 'Satılan Ürünler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },

  {
    path: '/consumedActivities',
    name: 'consumedActivities',
    component: () => import(/* webpackChunkName: "facility.product.consumedActivities" */ '@/views/facility/product/consumedActivities.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Consumed Activities'] : getKeyByValue(i18n.messages.tr, 'Tüketilen Activiteler'),
      breadcrumb: [
        {
          to: '/products',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Consumed Activities'] : getKeyByValue(i18n.messages.tr, 'Tüketilen Activiteler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },



  {
    path: '/qrCode/',
    name: 'qrcode',
    component: () => import(/* webpackChunkName: "facility.customer.qrCodeCreate" */ '@/views/facility/customer/qrCodeCreate.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr.QRCode : getKeyByValue(i18n.messages.tr, 'QRKod'),
      breadcrumb: [
        {
          to: '/qrcode',
          text: i18n.locale === 'tr' ? i18n.messages.tr.QRCode : getKeyByValue(i18n.messages.tr, 'QRKod'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },
  {
    path: '/facilityshop/:personID',
    name: 'facilityshop',
    component: () => import(/* webpackChunkName: "facility.sales.Shopping" */ '@/views/facility/sales/Shopping.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Shop'] : getKeyByValue(i18n.messages.tr, 'Satın Al'),
      breadcrumb: [
        {
          to: '/shop',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Shop'] : getKeyByValue(i18n.messages.tr, 'Satın Al'),
          active: true
        }
      ],
      isAuthRequired: true,
    }
  },

  {
    path: 'customerAppointment',
    name: 'customerAppointment',
    component: () => import(/* webpackChunkName: "facility.customer.customerAppointments" */ '@/views/facility/customer/customerAppointments.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Appointments'] : getKeyByValue(i18n.messages.tr, 'Randevular'),
      breadcrumb: [
        {
          to: '/customerAppointment',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Appointments'] : getKeyByValue(i18n.messages.tr, 'Randevular'),
          active: true
        }
      ],
      isAuthRequired: true,
    }
  },

  {
    path: '/AttendanceByTimePeriod',
    name: 'AttendanceByTimePeriod',
    component: () => import(/* webpackChunkName: "facility.report.AttendanceByTimePeriod" */ '@/views/facility/report/AttendanceByTimePeriod.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Participation Amount'] : getKeyByValue(i18n.messages.tr, 'Katılım Miktarı'),
      breadcrumb: [
        {
          to: '/reports',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Participation Amount'] : getKeyByValue(i18n.messages.tr, 'Katılım Miktarı'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },
  {
    path: '/PackagesSold',
    name: 'PackagesSold',
    component: () => import(/* webpackChunkName: "facility.report.PackagesSold" */ '@/views/facility/report/PackagesSold.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Packages Sold'] : getKeyByValue(i18n.messages.tr, 'Satılan Activiteler'),
      breadcrumb: [
        {
          to: '/reports',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Packages Sold'] : getKeyByValue(i18n.messages.tr, 'Satılan Activiteler'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },

  {
    path: '/CustomerActivityCount',
    name: 'CustomerActivityCount',
    component: () => import(/* webpackChunkName: "facility.report.CustomerActivityCount" */ '@/views/facility/report/CustomerActivityCount.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Number of Customer Activity'] : getKeyByValue(i18n.messages.tr, 'Number of Customer Activity'),
      breadcrumb: [
        {
          to: '/reports',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Number of Customer Activity'] : getKeyByValue(i18n.messages.tr, 'Number of Customer Activity'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },
  {
    path: '/TrainerActivityCount',
    name: 'TrainerActivityCount',
    component: () => import(/* webpackChunkName: "facility.report.TrainerActivityCount" */ '@/views/facility/report/TrainerActivityCount.vue'),
    meta: {
      pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Number of Trainer Activity'] : getKeyByValue(i18n.messages.tr, 'Number of Trainer Activity'),
      breadcrumb: [
        {
          to: '/reports',
          text: i18n.locale === 'tr' ? i18n.messages.tr['Number of Trainer Activity'] : getKeyByValue(i18n.messages.tr, 'Number of Trainer Activity'),
          active: true,
        },
      ],
      isAuthRequired: true,
    }
  },
];
