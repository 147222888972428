import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {

    /**Eğitmenlerin verebildiği aktiviteler */
    set_trainer_activity(p_company_branch_id,p_trainer_activity_types){
        const data = {p_company_branch_id,p_trainer_activity_types}
        return FintechPost.executePostFormData('set_trainer_activity',1,data)
    },

    trainer_detail_type_get_all_list(p_company_branch_id, p_trainer_id){
      const data = {p_company_branch_id,p_trainer_id}
      return FintechPost.executePostFormData('trainer_detail_type_get_all_list',1,data)
     },
     get_company_branch_name(p_company_branch_id){
       const data = {p_company_branch_id}
       return FintechPost.executePostFormData('get_company_branch_name', 1, data)
     },
    //trainer kişisi company_branch_person_role tablosunda is_confirm olmuş mu
    trainer_is_confirm_check(p_person_id, p_company_branch_id){
      const data = {p_person_id, p_company_branch_id}
      return FintechPost.executePostFormData('trainer_is_confirm_check', 1, data)
    },
    //company_branch_person_role e göre listeyi dön
    get_isconfirm_trainer_list(){
      const data = {}
      return FintechPost.executePostFormData('get_isconfirm_trainer_list', 1, data)
    },
    //company branch'a kullanıcıları dön
    get_company_branch_person_list(p_company_branch_id){
      const data = {p_company_branch_id}
      return FintechPost.executePostFormData('get_company_branch_person_list', 1, data)
    },
    //kullanıcı ile ilgili davet bilgileri
    get_person_invite_info(p_person_invite_id){
      const data = {p_person_invite_id}
      return FintechPost.executePostFormData('get_person_invite_info', 1, data)
    },  
    //invite tablosunda is_active false yapar, company_branch_person_role tablosunda is_confirm alanını true check eder
    invite_confirm_check(p_person_invite_id){
      const data = {p_person_invite_id}
      return FintechPost.executePostFormData('invite_confirm_check',1 , data)
    },
    //person invite id bilgisine göre company name getirmek
    get_company_branch_name_by_person_invite_id(p_person_invite_id){
      const data = {p_person_invite_id}
      return FintechPost.executePostFormData('get_company_branch_name_by_person_invite_id', 1, data)
    },
    //person invite id bilgisine göre person name getirmek
    get_person_name_by_person_invite_id(p_person_invite_id){
      const data = {p_person_invite_id}
      return FintechPost.executePostFormData('get_person_name_by_person_invite_id', 1, data)
    },
    //company_branch_person_id ve menu_role_group_id ye göre get_company_branch_role_id getirmek
    get_company_branch_role_id(p_company_branch_person_id, p_menu_role_group_id){
      const data = {p_company_branch_person_id, p_menu_role_group_id}
      return FintechPost.executePostFormData('get_company_branch_role_id', 1, data)
    },
    //company_branch_person_role_id bilgisine göre invite created ve resend dateleri getirir
    get_trainer_invite_infos(p_company_branch_person_role_id){
      const data = {p_company_branch_person_role_id}
      return FintechPost.executePostFormData('get_trainer_invite_infos', 1, data)
    },

    // api/Trainer/TrainerPersonCreate
    TrainerCreate(data= {PersonId:'',CompanyBranchId:'',FirstName:'',LastName:'',Email:'',PhoneNumber:'',Birthday:'',Gender:0, UserImg: null}){
      return Post.postFormData('api/Trainer/TrainerPersonCreate',data)
    },

    list_trainer_activities(p_company_branch_id, trainer_id)
    {
      const data= {CompanyBranchId:p_company_branch_id,TrainerId:trainer_id}
      return Post.postFormData('api/Trainer/ListTrainerActivities',data)
    },

    trainer_confirm(personInviteId)
    {
      const data= {personInviteId:personInviteId}
      return Post.postFormData('api/Trainer/TrainerConfirm',data)
    },




    /**
     * Yazar Şenel YILDIZ
     * Tarih : 23.08.2022
     * @param {*} p_company_branch_id distributorId
     * @param {*} p_is_active Aktifmi pasifmi
     * @param {*} p_search_text Aranacak kelime
     * @returns 
     */
    get_trainers(p_company_branch_id,p_is_active){
      const data = {CompanyBranchId:p_company_branch_id,IsActive:p_is_active}
      return Post.postFormData('api/Trainer/GetStudioTrainers',data)
    },

      /**
     * 
     * Yazar Şenel YILDIZ
     * Tarih : 15.12.2022
     * @param {*} p_company_branch_id distributorId
     */
      trainers_with_activity(p_company_branch_id){
        const data = {CompanyBranchId:p_company_branch_id}


        return Post.postFormData('api/Trainer/TrainersWithActivity',data)
      },

    /**
    * Yazar Şenel YILDIZ
    * Tarih : 16.09.2022
    * @param {*} p_company_branch_id distributorId
    * @param {*} p_is_active Aktifmi pasifmi
    * @returns 
    */
    get_active_trainers(p_company_branch_id) {
        const data = { CompanyBranchId: p_company_branch_id}
        return Post.postFormData('api/Trainer/GetTrainers', data)
    },



     /**
     * Yazar Şenel YILDIZ
     * Tarih 22.08.2022
     * Aktivite listesinde Aktif Pasif durumunu değiştirir

     * @param {Distributor Id} p_company_branch_id 
     * @param {p_is_active =0 False , p_is_active=1 True} 
     * @returns true yada false
     */
      trainer_is_activeChange(p_trainer_id,p_is_active){

        
        const data = {TrainerId:p_trainer_id,IsActive:p_is_active}

        console.log({"Data":data})
          return Post.postFormData('/api/Trainer/IsActiveChange', data)
      },



    get_studio_trainers(p_company_branch_id){
      const data = {p_company_branch_id}
      return FintechPost.executePostFormData('get_studio_trainers', 1, data)
    },
    
    get_studio_trainers_with_img(p_company_branch_id){
      const data = {p_company_branch_id}
      return FintechPost.executePostFormData('get_studio_trainers_with_img', 1, data)
    },
    get_trainer_img_by_trainer_id(p_trainer_id){
      const data = {p_trainer_id}
      return FintechPost.executePostFormData('get_trainer_img_by_trainer_id', 1, data)
    },

    get_trainer_id_by_person_and_company(p_person_id, p_company_branch_id){
      const data = {p_person_id, p_company_branch_id}
      return FintechPost.executePostFormData('get_trainer_id_by_person_and_company', 1, data)
    }

    // // api/Trainer/GetUserByMail
    // getUserByMail(data= {EMail, CompanyBranchId}){
    //   return Post.postFormData('api/Trainer/GetUserByMail', data)
    // },
    // //api/Trainer/NewPasswordByPersonInvite
    // NewPassword(data={UserId, ForgotPasswordRequestId, PersonInviteId, Password, ConfirmPassword}){
    //   return Post.postFormData('api/Trainer/NewPasswordByPersonInvite',data)
    // },
    
}

