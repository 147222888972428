/**
 * Gönderilen parametrenin 0 olup-olmadığını denetler. (v-money kullanımları için)
 * @param value
 * @returns {boolean}
 */
export default function (value) {
  if (value !== '' && value != undefined && value !== '0') {
    let val = ('' + value).split('.').join('')
    val = Number(val)
    if (val > 0) return true
    else return false
  } else {
    return false
  }
}
