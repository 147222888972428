<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]">
       <b-overlay
          :show="loading"
          rounded="large"
           blur="2px"
      >
    <component :is="layout">
      <router-view />
    </component>
     </b-overlay>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {BOverlay} from 'bootstrap-vue'
import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import {mapGetters} from 'vuex'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    created(){
      let me=this;
      this.$functions.Messages={
          success(i18n_message){
              me.$toast({
                  component: ToastificationContent,
                  props: {
                      title: i18n_message ? me.$t(i18n_message): me.$t('Success'),
                      icon: "CheckIcon",
                      variant: "success",
                  },
              });
          },
          warning(i18n_message) {
              me.$toast({
                  component: ToastificationContent,
                  props: {
                      title: i18n_message ? me.$t(i18n_message): me.$t('Warning'),
                      icon: "MinusIcon",
                      variant: "warning",
                  },
              });
          },
          info(i18n_message){
              me.$toast({
                  component: ToastificationContent,
                  props: {
                      title: i18n_message ? me.$t(i18n_message): me.$t('Info'),
                      icon: "InfoIcon",
                      variant: "info",
                  },
              });
          },
          error(i18n_message){
              me.$toast({
                  component: ToastificationContent,
                  props: {
                      title: i18n_message ? me.$t(i18n_message): me.$t('Danger'),
                      icon: "xIcon",
                      variant: "danger",
                  },
              });
          }
      }
    },
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BOverlay,
    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  watch:{
    '$store.state.loading' (val) {
      if (val) {
        try {
          this.$vs.loading({ color:'#7B70F1', type: 'radius', container: '.vs-con-loading__container-secondary', scale: 0.6})
        } catch (err) {
          this.$vs.loading()
        }
      } else {
        this.$vs.loading.close('.vs-con-loading__container-secondary > .con-vs-loading')
        this.$vs.loading.close()
      }
    },
  },
  computed: {
    ...mapGetters ( 'app', ['loading']),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    //document.title = "GX&PT"

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
