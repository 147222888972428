// export default (CompanyBranchId, FirstName, LastName, Email, PhoneNumber, Birthday,Identity,Address, Gender, UserImg) => ({
//   CompanyBranchId,
//   FirstName,
//   LastName,
//   Email,
//   PhoneNumber,
//   Birthday,
//   Gender,
//   UserImg,
//   IdentificationNumber:Identity,
//   Address
// })

export default (CompanyBranchId, FirstName, LastName, Email, PhoneNumber, Birthday,IdentificationNumber, Gender , CityId, TownId,IsAddressesEqual, Address, UserImg) => ({
  CompanyBranchId,
  FirstName,
  LastName,
  Email,
  PhoneNumber,
  Birthday,
  Gender,
  UserImg,
  IdentificationNumber:IdentificationNumber,
  //CountryId,
  CityId,
  TownId,
  IsAddressesEqual,
  Address
})