import DateFormat from '@/functions/DateFormat'
import DateFormatAndTime from '@/functions/DateFormatAndTime'
import DateFormatSql from '@/functions/DateFormatSql'
import DateValidate from '@/functions/DateValidate'
import DeleteEnterCaracter from '@/functions/DeleteEnterCaracter'
import EmailIsValid from '@/functions/EmailIsValid'
import HtmlToText from '@/functions/HtmlToText'
import IsDate from '@/functions/IsDate'
import IsEmailRegex from '@/functions/IsEmailRegex'
import IsNull from '@/functions/IsNull'
import IsNumeric from '@/functions/IsNumeric'
import IsUniqueIdentifier from '@/functions/IsUniqueIdentifier'
import IsZero from '@/functions/IsZero'
import MoneyFormat from '@/functions/MoneyFormat'
import NumberRegex from '@/functions/NumberRegex'
import PhoneNumberIsValid from '@/functions/PhoneNumberIsValid'
import QuoteFormat from '@/functions/QuoteFormat'
import ConvertDateWithLocalLanguage from './ConvertDateWithLocalLanguage'
import ConvertToEnglishDateFormat from '@/functions/ConvertToEnglishDateFormat'
import ConvertToTurkishDateFormat  from '@/functions/ConvertToTurkishDateFormat'
import UpdateDateStrPlus3 from '@/functions/UpdateDateStrPlus3'
import TomorrowAddDay from '@/functions/TomorrowAddDay'
import CountDownTimer from '@/functions/CountDownTimer'


//import Toast from '@/functions/Toast'


export default {
  DateFormat,
  DateFormatAndTime,
  DateFormatSql,
  DateValidate,
  DeleteEnterCaracter,
  EmailIsValid,
  HtmlToText,
  IsDate,
  IsEmailRegex,
  IsNull,
  IsNumeric,
  IsUniqueIdentifier,
  IsZero,
  MoneyFormat,
  NumberRegex,
  PhoneNumberIsValid,
  QuoteFormat,
  ConvertDateWithLocalLanguage,
  ConvertToEnglishDateFormat,
  ConvertToTurkishDateFormat,
  UpdateDateStrPlus3,
  TomorrowAddDay,
  CountDownTimer
  //Toast
}
