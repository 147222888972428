function getKeyByValue(object, value) {
    return Object.keys(object)
        .find(key => object[key] === value);
}

import i18n from '../../libs/i18n/index';

export default [
    {
        path: '/selectProduct',
        name: 'selectProduct',
        component: () => import(/* webpackChunkName: "customer.appointment.SelectProduct" */ '@/views/customer/appointment/selectProduct.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Select Product'] : getKeyByValue(i18n.messages.tr, 'Ürün Seç'),
            breadcrumb: [
                {
                    to: '/selectProduct',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['Select Product'] : getKeyByValue(i18n.messages.tr, 'Ürün Seç'),
                    active: true,
                },
            ],
            isAuthRequired: true,
        }
    },

    {
        path: '/activeTrainers',
        name: 'activeTrainers',
        component: () => import(/* webpackChunkName: "customer.trainer.trainersWithActivity" */  '@/views/customer/trainer/trainersWithActivity.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Trainers'] : getKeyByValue(i18n.messages.tr, 'Eğitmen Seç'),
            breadcrumb: [
                {
                    to: '/trainers',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['Trainers'] : getKeyByValue(i18n.messages.tr, 'Eğitmen Seç'),
                    active: true,
                },
            ],
            isAuthRequired: true,
        }
    },


    {
        path: '/myAppointments',
        name: 'myAppointments',
        component: () => import(/* webpackChunkName: "customer.appointment.myAppointments" */  '@/views/customer/appointment/myAppointments.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['My Appointments'] : getKeyByValue(i18n.messages.tr, 'Randevularım'),
            breadcrumb: [
                {
                    to: '/myAppointments',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['My Appointments'] : getKeyByValue(i18n.messages.tr, 'Randevularım'),
                    active: true,
                },
            ],
            isAuthRequired: true,
        }
    },
    {
        // companyBranchID: CustomerProductDetailCard.CustomerProductCardView.company_branch_id,
        // trainerID: CustomerProductDetailCard.TrainerDetailCardView.trainer_id,
        // activityID: CustomerProductDetailCard.ActivityDetailCardView.activity_id,
        // trainerProductID: CustomerProductDetailCard.TrainerProductCardView.trainer_product_id,
        // customerProductSalesID: CustomerProductDetailCard.CustomerProductCardView.customer_product_sales_id


        path: '/makeAppointment/:companyBranchID/:trainerID/:activityID/:trainerProductID/:customerProductSalesID',
        name: 'makeAppointment',
        component: () => import(/* webpackChunkName: "customer.appointment.makeAppointment" */ '@/views/customer/appointment/makeAppointment.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Make Appointment'] : getKeyByValue(i18n.messages.tr, 'Randevu Al'),
            breadcrumb: [
                {
                    to: '/makeAppointment',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['Make Appointment'] : getKeyByValue(i18n.messages.tr, 'Randevu Al'),
                    active: true,
                },
            ],
            isAuthRequired: true,
        }
    },
    {
        path: '/appointmentInfo/:timeTableDetailId',
        name: 'appointmentInfo',
        component: () => import(/* webpackChunkName: "customer.appointment.appointmentInfo" */ '@/views/customer/appointment/appointmentInfo.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            isAuthRequired: true,
        }
    },
    {
        path: '/purchased/',
        name: 'purchased',
        component: () => import(/* webpackChunkName: "customer.sales.Purchased" */ '@/views/customer/sales/Purchased.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Purchased'] : getKeyByValue(i18n.messages.tr, 'Satın Alma Geçmişi'),
            breadcrumb: [
                {
                    to: '/shop',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['Purchased'] : getKeyByValue(i18n.messages.tr, 'Satın Alma Geçmişi'),
                    active: true
                }
            ],
            isAuthRequired: true,
        }
    },
    {
        path: '/shop/:role/:personID',
        name: 'shop',
        component: () => import(/* webpackChunkName: "customer.sales.Shopping" */ '@/views/customer/sales/Shopping.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Shop'] : getKeyByValue(i18n.messages.tr, 'Satın Al'),
            breadcrumb: [
                {
                    to: '/shop',
                    text: i18n.locale === 'tr' ? i18n.messages.tr['Shop'] : getKeyByValue(i18n.messages.tr, 'Satın Al'),
                    active: true
                }
            ],
            isAuthRequired: true,
        }
    },

    // Hata giderilmesi için düzenleme yapılacak
    //qr code ile katılmak için public invite route
    {
        path: '/customerInvite',
        name: 'customer-public-invite',
        component: () => import(/* webpackChunkName: "customer.customer.CustomerPublicInvite" */ '@/views/customer/CustomerPublicInvite.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/CustomerManualActivate/',
        name: 'CustomerManualActivate',
        component: () => import(/* webpackChunkName: "customer.sales.Purchased" */ '@/views/customer/activate/customerManualActivate.vue'),
        meta: {
            pageTitle: i18n.locale === 'tr' ? i18n.messages.tr['Confirm User'] : getKeyByValue(i18n.messages.tr, 'Kullanıcıyı Onayla'),
            breadcrumb: [
                {
                    text: i18n.locale === 'tr' ? i18n.messages.tr['Confirm User'] : getKeyByValue(i18n.messages.tr, 'Kullanıcıyı Onayla'),
                    active: true
                }
            ],
            isAuthRequired: true,
        }
    },

    //qr code ile katılmak için public invite route
    // {
    //     path: '/customerInvite/:param1=',
    //     name: 'customer-public-invite',
    //     component: () => import('@/views/customer/CustomerPublicInvite.vue'),
    //     meta: {
    //         layout: 'full',
    //         resource: 'Auth',
    //     },
    // }
];
