export default (PersonId,TrainerId,TrainerProductId,ProductName,Identity, CustomerName,PhoneNumber,Email, Date, StartTime,EndTime,TrainerName,Status) => ({
    PersonId :PersonId ? PersonId:'00000000-0000-0000-0000-000000000000' ,
    TrainerId:TrainerId ? TrainerId: '00000000-0000-0000-0000-000000000000',
    TrainerProductId:TrainerProductId ? TrainerProductId : '00000000-0000-0000-0000-000000000000',
    ProductName:ProductName? ProductName :"default",
    Identity:Identity ? Identity :"",
    CustomerName: CustomerName ? CustomerName : "default",
    PhoneNumber:PhoneNumber ? PhoneNumber:"",
    Email:Email ? Email : "",
    Date:Date ? Date :"",
    StartTime:StartTime ? StartTime :"",
    EndTime:EndTime ? EndTime:"",
    TrainerName:TrainerName ? TrainerName :"",
    Status:Status ? Status :"",
 })
