import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {
  /**
   *
   * @param {Email,Password} p_data
   * @returns
   */
  account_login(p_data){
    return Post.postFormData('api/Account/Login', p_data);
  },
  forgot_password(data={email}){
    return Post.postFormData('api/Account/ForgotPassword',data)
  },
  forgot_password_change(data={ForgotPasswordRequestId, Password, ConfirmPassword}){
    return Post.postFormData('api/Account/ForgotPasswordChange',data)
  },
  change_password(data= {OldPassword,NewPassword}){
    return Post.postFormData('api/Account/ChangePassword',data)
  },
  //email ve company branch id bilgisine göre user bilgisi döner
  get_user_by_mail(data= {EMail, CompanyBranchId}){
    return Post.postFormData('api/Account/GetUserByMail', data)
  },
  //kullanıcı davetinde şifre belirleme için
  new_password_by_person_invite(data= {UserId, ForgotPasswordRequestId, PersonInviteId, Password, ConfirmPassword}){
    return Post.postFormData('api/Account/NewPasswordByPersonInvite', data)
  },
  re_send_invite(data= {CompanyBranchPersonRoleId, PersonId}){
    return Post.postFormData('api/Account/ReSendInvite', data)
  },
  //kullanıcı ile ilgili davet bilgileri
  get_person_invite_info(p_person_invite_id){
    const data = {p_person_invite_id}
    return FintechPost.executePostFormData('get_person_invite_info', 1, data)
  },
  //login olunca gelecek seçenekler
  get_login_options_list(p_email){
    const data = {p_email}
    return FintechPost.executePostFormData('get_login_options_list', 1, data)
  },
  //ad, soyad güncelleme
  update_name_lastname_dropdown(p_person_id, p_email, p_company_branch_id, p_name, p_last_name){
    const data = {p_person_id, p_email, p_company_branch_id, p_name, p_last_name}
    return FintechPost.executePostFormData('update_name_lastname_dropdown', 1, data)
  },
  //resim base64 bilgisini getirme
  get_person_image(p_person_id){
    const data = {p_person_id}
    return FintechPost.executePostFormData('get_person_image', 1, data)
  },

  invite_confirm_check(personInviteId)
  {
    const data= {personInviteId:personInviteId};
    return Post.postFormData('api/Account/InviteConfirmCheck',data)
  }

}
