import ConvertToEnglishDateFormat from "@/functions/ConvertToEnglishDateFormat"


export default function(dateValue) {
    const cultureInfo = navigator.language;
    // 25.12.2022 -> 12/25/22 
    if(cultureInfo === 'tr-TR'){

       // console.log("Yeni tarih işte bu : ");

        let newDate=  ConvertToEnglishDateFormat(dateValue);
        // let trDateParsed = dateValue.split('.'); //['25','12','2022']
        // let newDate = trDateParsed[1] + '/' + trDateParsed[0] + '/' + trDateParsed[2];

        console.log("Yeni tarih işte bu : " + newDate);


        return newDate;
    } else if(cultureInfo === 'en-US') {
        return dateValue;
    } else {
        return dateValue;
    }
}