export default function(countDownDate)
{
    var countDownDate = new Date("Jan 4, 2023 15:37:25").getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {
    
      // Get today's date and time
      var now = new Date().getTime();
        
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
        
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);  
      var date="";
        
        if(days > 0)
        {
            date = days + " gün " +  hours + ":" + minutes + ":" + seconds + "s ";
        }
        else
        {
            date=	hours + ":" + minutes + ":" + seconds + "s ";
        }
        
      // Output the result in an element with id="demo"
      document.getElementById("demo").innerHTML = date; //days == 0 ? "": days + " gün " + hours + ":"
      + minutes + ":" + seconds;
        
      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
}