function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
function getLocaleMessage(message) {
  return i18n.messages[i18n.locale][message] || message;
}
import i18n from "../../libs/i18n/index";

export default [
];
