import * as functions from '@/mixins/functions'

export default {
    namespaced: true,
    state: {
        licence_data:[],
        invited_users_data: [],
        active_users_data: [],
        licence_data:[]
    },
    getters: {
        licence_data: (state) => state.licence_data,
        invited_users_data: (state) => state.invited_users_data,
        active_users_data: (state) => state.active_users_data,
        licence_data: (state) => state.licence_data
    },
    mutations: {
        licence_data: (state,payload) => state.licence_data = payload,
        invited_users_data: (state, payload) => state.invited_users_data = payload,
        active_users_data: (state, payload) => state.active_users_data = payload,
        licence_data: (state, payload) => state.licence_data = payload
    },
    actions: {
        GET_LICENCE_DATA({commit}, {p_company_branch_id}){
            DB.DistributorService.get_studio_licence(p_company_branch_id).then(
                (res) => {
                  if (!res.is_success || !res.result) return;
                    const result = res.result.map((el) => {
                      if(el.remaining === 0){
                        return {
                          ...el,
                          //end_date:el.end_date.split('T')[0] + '  '+ el.end_date.split('T')[1],
                          //start_date:el.start_date.split('T')[0] +'  '+ el.start_date.split('T')[1],
                          remaining: 'LİSANS SÜRESİ DOLMUŞ',//this.$t("LISANS TIME IS EXPIRED"),
                          is_active: 'PASİF',//this.$t("PASSIVE"),
                          start_date: functions.dateFormat_without_time(el.start_date),
                          end_date: functions.dateFormat_without_time(el.end_date)
                        };
                      }
                      else{
                      if (el.is_active === '1') {
                        return {
                          ...el,
                          //end_date:el.end_date.split('T')[0] + '  '+el.end_date.split('T')[1],
                          //start_date:el.start_date.split('T')[0] +'  '+ el.start_date.split('T')[1],
                          is_active: 'AKTİF',//this.$t("ACTIVE"),
                          start_date: functions.dateFormat_without_time(el.start_date),
                          end_date: functions.dateFormat_without_time(el.end_date)
                        };
                      } else {
                        return {
                          ...el,
                          //end_date:el.end_date.split('T')[0] + '  '+ el.end_date.split('T')[1],
                          //start_date:el.start_date.split('T')[0] +'  '+ el.start_date.split('T')[1],
                          is_active: 'PASİF',//this.$t("PASSIVE"),
                          start_date: functions.dateFormat_without_time(el.start_date),
                          end_date: functions.dateFormat_without_time(el.end_date)
                        };
                      }
                      }
                    })
                    // console.log(result)
                    commit('licence_data',result)
                    // this.studio_data = res.result
                }
              );
        },
        GET_INVITED_USERS_DATA({ commit }, { p_company_branch_id }) {
            DB.DistributorService.studio_get_user_list(p_company_branch_id, '0')
                .then((res) => {
                    if (!res.is_success || !res.result) return;
                    const result = res.result.map(el => {
                        const full_name = el.name + ' ' + el.last_name
                        return {
                            ...el,
                            full_name: full_name,
                            created_date: functions.dateFormat(el.created_date)
                        }
                    });
                    commit('invited_users_data', result)
                })
        },
        GET_ACTIVE_USERS_DATA({ commit }, { p_company_branch_id }) {
            DB.DistributorService.studio_get_user_list(p_company_branch_id, '1')
                .then((res) => {
                    if (!res.is_success || !res.result) return;
                        const result = res.result.map(el => {
                            const full_name = el.name + ' ' + el.last_name
                            return {
                                ...el,
                                full_name: full_name
                            }
                        });
                       commit('active_users_data', result)
                });
        }
      //   GET_LICENCE_DATA({ commit }, { p_company_branch_id }) {
      // DB.DistributorService.get_studio_licence(p_company_branch_id).then(
      //   (res) => {
      //     if (!res.is_success || !res.result) return;
      //       const result = res.result.map((el) => {
      //         if (el.is_active === '1') {
      //           return {
      //             ...el,
      //           //   end_date:el.end_date.split('T')[0] + '  '+el.end_date.split('T')[1],
      //           //   start_date:el.start_date.split('T')[0] +'  '+ el.start_date.split('T')[1],
      //             is_active:'AKTİF',// this.$t("ACTIVE"),
      //             start_date: functions.dateFormat_without_time(el.start_date),
      //             end_date: functions.dateFormat_without_time(el.end_date)
      //           };
      //         } else {
      //           return {
      //             ...el,
      //           //   end_date:el.end_date.split('T')[0] + '  '+ el.end_date.split('T')[1],
      //           //   start_date:el.start_date.split('T')[0] +'  '+ el.start_date.split('T')[1],
      //             remaining:'LİSANS SÜRENİZ DOLMUŞTUR', //this.$t("LISANS TIME IS EXPIRED"),
      //             is_active:'PASİF', //this.$t("PASSIVE"),
      //             start_date: functions.dateFormat_without_time(el.start_date),
      //             end_date: functions.dateFormat_without_time(el.end_date)
      //           };
      //         }
      //       });
      //       console.log('result',result)
      //       commit('licence_data',result)
      //     });
      //   }

    }
}
