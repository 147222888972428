import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'
export default {
    activity_detail_type_get_all_list(p_company_branch_id, p_room_id){
        const data = {p_company_branch_id,p_room_id}
        return FintechPost.executePostFormData('activity_detail_type_get_all_list',1,data)
    },


    // /api/Appointment/GetAppointment
    appointment_filter_activities(CompanyBranchId, TrainerId) {
        const data = { CompanyBranchId: CompanyBranchId, TrainerId: TrainerId}
        return Post.postFormData('/api/Activity/AppointmentFilterActivities', data);
    }
}