import Vue from 'vue'
Vue.mixin({
    methods: {
        dateFormat(val){
           let date = val.split('T')[0].split('-').reverse().join('.')
           let hour = val.split('T')[1].split('.')[0]
           return date + ' ' + hour
        },
        dateFormat_without_time(val){
           let date = val.split('T')[0].split('-').reverse().join('.')
           return date 
        }
    }
})
export const dateFormat = (val) =>{
    let date = val.split('T')[0].split('-').reverse().join('.')
    let hour = val.split('T')[1].split('.')[0]
    return date + ' ' + hour
 }

 export const dateFormat_without_time = (val)=>{
    let date = val.split('T')[0].split('-').reverse().join('.')
    return date 
 }