export default (CompanyBranchId, FirstName, LastName, Email, PhoneNumber, Birthday, Gender, UserImg) => ({
  CompanyBranchId,
  FirstName,
  LastName,
  Email,
  PhoneNumber,
  Birthday,
  Gender,
  UserImg
})
