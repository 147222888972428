import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'

export default {
    //o stüdyoya ait tüm ürünlerin listesini geri döndürür
    get_product_list(p_company_branch_id)
    {
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_product_list', 1, data)
    },
    get_activity_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_activity_list', 1, data)
    },
    get_trainer_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_trainer_list', 1, data)
    },
    get_trainer_name_by_trainer_id(p_trainer_id){
        const data = {p_trainer_id}
        return FintechPost.executePostFormData('get_trainer_name_by_trainer_id', 1, data)
    },
    get_product_price(p_product_detail_id){
        const data = {p_product_detail_id}
        return FintechPost.executePostFormData('get_product_price', 1, data)
    },
    get_trainers_products_for_sale(company_branch_id, trainer_id, activity_id) {
        const data = { CompanyBranchId: company_branch_id, TrainerId:trainer_id , ActivityId: activity_id}
      return Post.postFormData('api/TrainerProduct/TrainersProductsForSale', data)
    },
}