import modecalendarEditv2ls from "../../models/index";
import DataBase from '@/services/DataBase'


function format(val){
  return val.split('T')[0] + ' ' + val.split('+')[0].split('T')[1]
}

export default {
  namespaced: true,
  state: {
    events: {
      salon1: [
        {
          title: "My Event",
          start: "2021-11-17 09:30",
          end: "2021-11-17 10:30",
          textColor: "#fff",
          description: "Sabah sporu",
          //eventTextColor:'white',
          type: 2, // Custom field
        },
      ],
      salon2: [
        {
          title: "My Event",
          start: "2021-11-17 10:30",
          end: "2021-11-17 11:30",
          textColor: "#fff",
          description: "Sabah sporu",
          //eventTextColor:'white',
          type: 2, // Custom field
        },
      ],
    },
  },
  getters: {
    events: (state) => state.events,
  },
  mutations: {
    events: (state, payload) => (state.events = payload),
  },
  actions: {
    async addAppointment(context, payload) { //payload = {Appointment, Room}
      /*
      const event = {
        id: new Date().getTime(),
        title: appointment.title,
        editable: false,
        start: appointment.startDate + " " + appointment.startTime,
        end: appointment.endDate + " " + appointment.endTime,
      };

      store.state.events.salon2.push(event);


      record_time_table(p_company_branch_id, p_subject_title, p_description, p_trainer_product_id, p_trainer_id,
        p_activity_id, p_start_date, p_end_date, p_capacity ){
        const data = {p_company_branch_id, p_subject_title, p_description, p_trainer_product_id, p_trainer_id,
        p_activity_id, p_start_date, p_end_date, p_capacity}
        return FintechPost.executePostFormData('record_time_table', 1, data)
    }
    */
      let response
      await DataBase.SchedulerService.record_time_table(
        payload.Appointment.company_branch_id,
        payload.Appointment.title,
        payload.Appointment.definition,
        payload.Appointment.trainer_id,
        payload.Appointment.activity_id,
        format(payload.Appointment.startStr),
        format(payload.Appointment.endStr),
        payload.Appointment.room_id,
        payload.Appointment.capacity,
        JSON.stringify(payload.Appointment.participantsList)
       ).then(res => {
        if(res.is_success === true || res.is_success === 1){
          response =  {
            status: true,
            messages: 'Planlayıcıya etkinlik eklemesi başarıyla gerçekleştirildi'
          }
        }else {
          response = {
            status: false,
            messages: 'Planlayıcıya etkinlik eklemesi başarısız oldu'
          }
        }
      })
      return response;


    },

    async deleteAppointment(context, payload) { // payload = {TimeTableId}
      let response
      await DataBase.SchedulerService.delete_time_table_record(
        payload.TimeTableId
      ).then(res => {
        if(res.is_success === true || res.is_success === 1){
          response = {
            status: true,
            messages: 'Planlayıcıdan etkinlik silinmesi başarıyla gerçekleştirildi'
          }
        } else {
          response = {
            status: false,
            messages: 'Planlayıcıdan etkinlik silinmesi başarısız oldu'
          }
        }
      })
      return response
    },

    async updateAppointment(context, payload) { //payload = {Appointment,TimeTableId}
      let response
      await DataBase.SchedulerService.update_time_table(
        payload.TimeTableId,
        payload.Appointment.company_branch_id,
        payload.Appointment.title,
        payload.Appointment.definition,
        payload.Appointment.trainer_product_id,
        payload.Appointment.trainer_id,
        payload.Appointment.activity_id,
        format(payload.Appointment.startStr),
        format(payload.Appointment.endStr),
        payload.Appointment.room_id,
        payload.Appointment.capacity
      ).then(res => {
        if(res.is_success === true || res.is_success === 1){
          response = {
            status: true,
            messages: 'Planlayıcıdan etkinlik güncellemesi başarıyla gerçekleştirildi'
          }
        } else {
          response = {
            status: false,
            messages: 'Planlayıcıdan etkinlik güncellemesi başarısız oldu'
          }
        }
      })
      return response
    }
  },
};
