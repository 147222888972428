import FintechPost from './BaseConnectCommand/FintechPost'
import Post from './BaseConnectCommand/Post'

export default {
    get_studio_by_id(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_by_id',1,data)
    },
    get_studio_list(){
        const data = {}
        return FintechPost.executePostFormData('get_studio_list',1,data)
    },
    /** Execute End point üzerinde yapılan Tehsis güncelleme işlemi
     * Online Ödeme entegrasyonu yapılacağından
     * FacilityController içindeki FacilityUpdate Actionuna taşındı
     * Bu kısım ve bu kısma ait olan sp tüm işlemler tamalandıktan sonara silinecek
     */
    company_update(p_company_branch_id, 
        p_name,
        p_title, 
        p_company_official_title,
        p_phone_number, 
        p_city_id, 
        p_town_id, 
        p_adress, 
        p_email,){
        const data = {
            p_company_branch_id, 
            p_name,p_title, 
            p_company_official_title, 
            p_phone_number, 
            p_city_id, 
            p_town_id,
            p_adress,
             p_email,
             p_company_type:false
            }
        return FintechPost.executePostFormData('company_update',2,data)
    },



     /** Execute End point üzerinde yapılan Tehsis güncelleme işlemi
     * Online Ödeme entegrasyonu yapılacağından
     * FacilityController içindeki FacilityUpdate Actionuna taşındı.
     * Guncelleme işlemi için bu servis kullanılacak
     */
  
      
    // company_update(company_branch_id, 
    //     name,
    //     title, 
    //     company_official_title,
    //     phone_number, 
    //     city_id, 
    //     town_id, 
    //     tax_administration, 
    //     tax_code,
    //     adress,
    //     iban,
    //     email,
    //     company_type)
    // {
    //     const data = {
    //         company_branch_id, 
    //         name,
    //         title,
    //         company_official_title, 
    //         phone_number, 
    //         city_id, 
    //         town_id,
    //         tax_administration,
    //         tax_code,
    //         adress,
    //         iban,
    //         email,
    //         company_type
    //     }
       
    //     console.log("Giden data",data)
       
    //     Post.postFormData('/api/Facility/FacilityUpdate',data)
    // },
     

       
    payment_add_seller(SellerId,
    CompanyType,
    SellerCode,
    Iban,
    AccountOwnerName,
    AccountOwnerLastName,
    CommissionValue
    )
    {
    const data = {SellerId,
        CompanyType,
        SellerCode,
        Iban,
        AccountOwnerName,
        AccountOwnerLastName,
        CommissionValue
    }
       return Post.postFormData('/api/Facility/AddSeller',data)
    },

    payment_get_seller(sellerId)
    {
        const data = {sellerId: sellerId}
        return Post.postFormData('api/Facility/GetSeller',data)
    },



    company_create(
        company_name,
        title, 
        company_official_title,
        phone_number,
        email,
        city_id,
        town_id,
        adress)
        {const data = {
            Name:company_name,
            Title:title, 
            CompanyOfficialTitle:company_official_title,
            PhoneNumber:phone_number,
            Email:email,
            CityId:city_id,
            TownId:town_id,
            Adress:adress,
            CompanyType:false
        }
        return Post.postFormData('/api/Company/CompanyCreate',data)
    },

     // /api/Appointment/GetAppointment
     self_company_create( CompanyName,FirtName,LastName,PhoneNumber,Email)
     {
       const data={CompanyId:"00000000-0000-0000-0000-000000000000",
                    CompanyBranchId:"00000000-0000-0000-0000-000000000000",
                    CountryId:"00000000-0000-0000-0000-000000000000",
                    CityId:"00000000-0000-0000-0000-000000000000",
                    TownId:"00000000-0000-0000-0000-000000000000",
                    CompanyName,
                    FirtName,
                    LastName,
                    PhoneNumber,
                    Email}
        return Post.postFormData('/api/Company/SelfCompanyCreate', data);
    },

        //Execute ile şehirleri listele
    studio_get_cities(){
        const data = {}
        return FintechPost.executePostFormData('studio_get_cities',1,data)
    },

    //AddressController end pointi ile token almadan şehirleri listele
    //Varsayılan ülke türkiye
    get_cities(){
        const data = {}
        return Post.postFormData('/api/Address/GetCities', data)
    },

    //AddressController end pointi ile token almadan ilçeleri listele
    get_towns_by_city_id(p_city_id){
        const data = {prmCityId:p_city_id}
        return Post.postFormData('/api/Address/GetTowns', data)
    },



    studio_get_towns_by_city_id(p_city_id){
        const data = {p_city_id}
        return FintechPost.executePostFormData('studio_get_towns_by_city_id',1,data)
    },
    studio_get_user_list(p_company_branch_id,p_is_active){
        const data = {p_company_branch_id,p_is_active}
        return FintechPost.executePostFormData('get_user_list',1,data)
    },
    check_studio_user(p_company_branch_id,p_email)
    {
        const data = {p_company_branch_id,p_email}
        return FintechPost.executePostFormData('check_studio_user',1,data)
    },
    create_studio_user(p_company_branch_id,p_name,p_last_name,p_email)
    {
        const data = {p_company_branch_id,p_name,p_last_name,p_email}
        return FintechPost.executePostFormData('create_studio_user',1,data)
    },
    get_role_name_by_id(p_menu_role_group_id){
        const data = {p_menu_role_group_id}
        return FintechPost.executePostFormData('get_role_name_by_id',1,data)
    },
    get_studio_licence(p_company_branch_id)
    {
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_licence',1,data)
    },
    get_studio_name(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_studio_name', 1, data)
    },
    get_licence_date(p_company_branch_id)
    {
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_licence_date',1,data)
    },
    create_licence(p_company_branch_id,p_start_date,p_end_date)
    {
    const data = {p_company_branch_id,p_start_date,p_end_date}
    return FintechPost.executePostFormData('create_licence',1,data)
    },
    licence_deactive(p_licance_id,p_is_active)
    {
    const data = {p_licance_id,p_is_active}
    return FintechPost.executePostFormData('licance_deactive',1,data)
    },
    person_deactive(p_person_id,p_is_active)
    {
    const data = {p_person_id,p_is_active}
    return FintechPost.executePostFormData('person_deactive',1,data)
    },
    studio_deactive(p_company_branch_id,p_is_active)
    {
    const data = {p_company_branch_id,p_is_active}
    return FintechPost.executePostFormData('studio_deactive',1,data)
    },
    get_company_branch_person_branchid_and_personid(p_company_branch_id,p_person_id){
        const data = {p_company_branch_id,p_person_id}
        return FintechPost.executePostFormData('get_company_branch_person_branchid_and_personid',1,data)
    },
    create_person_and_distributor(p_company_branch_id,p_name,p_last_name,p_email,p_phone_number,p_birthday,p_gender,p_person_image){
        const data = {p_company_branch_id, p_name, p_last_name, p_email,p_phone_number,p_birthday,p_gender, p_person_image}
        return FintechPost.executePostFormData('create_person_and_distributor',1 , data)
    },
    update_distributor_role_group(p_company_branch_id, p_person_id){
        const data = {p_company_branch_id, p_person_id}
        return FintechPost.executePostFormData('update_distributor_role_group', 1, data)
    },
    person_get_by_email_limit(p_company_branch_id, p_email){
        const data = {p_company_branch_id, p_email}
        return FintechPost.executePostFormData('person_get_by_email_limit', 1, data)
    },
    person_get_by_email_and_facility(p_company_branch_id,p_email){
        const data = {p_company_branch_id,p_email}
        return FintechPost.executePostFormData('person_get_by_email_and_facility',1,data)
    },
    get_distributor_list(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_distributor_list',1, data)
    },
    get_distributor_person_list(){
        const data = {}
        return FintechPost.executePostFormData('get_distributor_person_list', 1, data)
    },
    get_person_and_company_branch_person_data(p_company_branch_id){
        const data = {p_company_branch_id}
        return FintechPost.executePostFormData('get_person_and_company_branch_person_data', 1, data)
    },

    // api/Distributor/DistributorPersonCreate
    DistributorPersonCreate(data= {CompanyBranchId:'',FirstName:'',LastName:'',Email:'',PhoneNumber:'',Birthday:'',Gender:0, UserImg: ''}){
        return Post.postFormData('api/Distributor/DistributorPersonCreate',data)
    },
    
    //invite tablosunda is_active false yapar, company_branch_person_role tablosunda is_confirm alanını true check eder
    invite_confirm_check(p_person_invite_id){
        const data = {p_person_invite_id}
        return FintechPost.executePostFormData('invite_confirm_check',1 , data)
    },
    //person invite id bilgisine göre company name getirmek
    get_company_branch_name_by_person_invite_id(p_person_invite_id){
        const data = {p_person_invite_id}
        return FintechPost.executePostFormData('get_company_branch_name_by_person_invite_id', 1, data)
    },
    //person invite id bilgisine göre person name getirmek
    get_person_name_by_person_invite_id(p_person_invite_id){
        const data = {p_person_invite_id}
        return FintechPost.executePostFormData('get_person_name_by_person_invite_id', 1, data)
    },
    //kullanıcı ile ilgili davet bilgileri
    get_person_invite_info(p_person_invite_id){
       const data = {p_person_invite_id}
       return FintechPost.executePostFormData('get_person_invite_info', 1, data)
    },    

    // api/Facility/FacilityPersonCreate
    FacilityPersonCreate(data= {CompanyBranchId:'',FirstName:'',LastName:'',Email:'',PhoneNumber:'',Birthday:'',Gender:0, UserImg: null})
    {
        return Post.postFormData('api/Facility/FacilityPersonCreate',data)
    },

    distributor_confirm(personInviteId)
    {
      const data= {personInviteId:personInviteId}
      return Post.postFormData('api/Distributor/DistributorConfirm',data)
    },
}
