import FintechPost from './BaseConnectCommand/FintechPost'

export default {
    menu_role_group_get(){
        const data = {}
        return FintechPost.executePostFormData('menu_role_group_get',1,data)
    },
    menu_role_group_create(p_group_name,p_allowed_menus){
        const data = {p_group_name,p_allowed_menus}
        return FintechPost.executePostFormData('menu_role_group_create',1,data)
    },
    menu_role_group_delete(p_menu_role_group_id){
        const data = {p_menu_role_group_id}
        return FintechPost.executePostFormData('menu_role_group_delete',1,data)
    },
    menu_role_group_update(p_menu_role_group_id,p_group_name,p_allowed_menus){
        const data = {p_menu_role_group_id,p_group_name,p_allowed_menus}
        return FintechPost.executePostFormData('menu_role_group_update',1,data)
    }
}
